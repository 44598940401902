<div class="jumbotron about_splash bk-black close-gap-bottom over-z top-shadow-white">
	<div class="container ">
		<div class="row mobileMargin">
			<div id="Enquiries" class="col-md-12 section-tagheader">
				<h2 class="roboto-medium white">General Enquiries<img class="" src="../../assets/tag-line-white.png"
						alt="arrow" /></h2>
			</div>
			<div class="col-md-12 white hidden-md hidden-lg spacer">

			</div>
			<div class="row spacer">
				<div class="col-md-5 white">

				</div>
				<div class="col-md-6 text-block">
					<div class="roboto white ">

						<div><b>E </b>
							<img class="email_png" src="../../assets/office_email.png" alt="" />
						</div>
						<br />
						<div><b>T &nbsp; </b>
							+1 204 235 0325
						</div>
						<br />
						<div><b> MAIN OFFICE </b>
						</div>
						516 St.Catherine Street <br />
						Winnipeg, MB &nbsp; R2J0N1 <br />
						Canada
						<br />
						<a href="https://goo.gl/maps/HjZYkEvkaxK2" target="_blank">MAP</a>
					</div>
				</div>
			</div>

		</div>
	</div>
</div>