import { Component, OnInit } from '@angular/core';
import { Router, NavigationEnd, ActivatedRoute } from '@angular/router';
import { Location } from '@angular/common';

@Component({
  selector: 'app-items',
  templateUrl: './items.component.html',
  styleUrls: ['./items.component.css']
})
export class ItemsComponent implements OnInit {

  private fragment: string;

  constructor(private route:ActivatedRoute) {}


  ngOnInit(): void {
  	this.route.fragment.subscribe(fragment => {this.fragment = fragment; })
  }

  ngAfterViewInit():void {
  	try {
  		document.querySelector('#' + this.fragment).scrollIntoView();
  	} catch (e){}
  }

}







  // goTo(id:string){
  //   this.router.navigate(["/"], { fragment: id }).then(res => {
  //     console.log(res);
  //     let testElement = document.getElementById(id)
  //     console.log("id: "+ id + " el:", testElement);
  //     if (testElement != undefined) testElement.scrollIntoView();
  //   });
  // }
