<div class="jumbotron about_splash bk-black close-gap-bottom over-z  ">
	<div class="container">
		<div class="row mobileMargin">
			<div class="col-md-12 section-tagheader">
				<h2 class="roboto-medium white">Profile<img class="" src="../../assets/tag-line-white.png" alt="" />
				</h2>
			</div>
			<div class="col-md-12 white hidden-md hidden-lg spacer">

			</div>
			<div class="row spacer">
				<div class="col-md-5 white">

				</div>
				<div class="col-md-6 text-block">
					<p class="roboto white ">

						VOITEC Architect was founded by Wojtek Rzeszowski in Winnipeg, Manitoba in 2001. Our practice
						now operates both in Ontario and Manitoba and we've made a name for ourselves specializing in
						First Nations projects in remote northern communities.
						<br>
						Our work is characterized by strong conceptual legibility, innovation and a rigorous approach to
						detailing, all underpinned by the principles of useful, enduring and sustainable design.
						<br>
						We specialize in new building design, community housing units, condominium conversions and
						commercial products. Our practice offers personalized design, interior design, site planning,
						economic feasibility studies, budget preparation and specification and construction services.
						<br>
						Our ultimate goal is to design buildings and environments that work, inspire people and
						transform communities. Through careful evaluation of relevant opportunities, inspired ideas will
						drive a good design to something that is extraordinary, challenging, and completely unique.


					</p>
				</div>
			</div>

		</div>
	</div>
</div>