<div class="jumbotron entice_splash bk-white close-gap-bottom">
	<div class="container">
		<div class="row mobileMargin">
			<div class="col-md-12 section-tagheader">
				<h2 class="roboto-medium black">Our Team<img class="" src="../../assets/tag-line.png" alt="arrow" />
				</h2>
			</div>
			<div class="col-md-12 black hidden-md hidden-lg spacer">

			</div>
			<div class="row spacer">
				<div class="col-md-5 black">
				</div>
				<div class="col-md-6 text-block">
					<div class="roboto black ">

						<b>Wojtek Rzeszowski &nbsp;</b> MAA, OAA, RAIC
						<br />
						<div><em>Principal </em>
							<img class="email_png" src="../../assets/wojtek_email.png" alt="" />
						</div>
						<br /><br />

						<b>Alek Rzeszowski </b>
						<br />
						<div><em>IT, Designer </em>
							<img class="email_png" src="../../assets/office_email_black.png" alt="" />
						</div>
						<br /><br />

						<b>Bozena Rzeszowska</b>
						<br />
						<div><em>M.Arch, Partner </em>
							<img class="email_png" src="../../assets/office_email_black.png" alt="" />
						</div>
						<br /><br />

					</div>
				</div>
			</div>

		</div>
	</div>
</div>