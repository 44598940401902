		
			<div  class="jumbotron trust_splash bk-grey close-gap-bottom ">
			    <div class="container ">
			
					 <div class="row ">
					        <div class="col-md-12 section-tagheader">
					          <h2 class="roboto-medium f-upper f-reg-weight f-space-2 u-inlineBlock">Trusted By </h2>
					        </div>
					  </div>


			      <div class="row">
			          <div class="col-xs-6 col-sm-4 col-md-4 col-lg-4 trust-item uofm">
			          		<img class="trust-logo noselect" src="../../assets/logos/logo-uofm.png" alt="uofm" />
			          </div>
			          <div class="col-xs-6 col-sm-4 col-md-4  col-lg-4 trust-item bearskin">
			          		<img class="trust-logo noselect" src="../../assets/logos/logo-bearskin.png" alt="bearskin" />
			          </div>
			          <div class="col-xs-6 col-sm-4 col-md-4  col-lg-4 trust-item lacseul">
			          		<img class="trust-logo noselect" src="../../assets/logos/logo-lacseul.png" alt="lacseul" />
			          </div>

			          <div class="col-xs-6 col-sm-4 col-md-4  col-lg-4 trust-item fortgarry">
			          		<img class="trust-logo noselect" src="../../assets/logos/logo-fortgarry.png" alt="fortgarry" />
			          </div>
			          <div class="col-xs-6 col-sm-4 col-md-4  col-lg-4 trust-item yellowquill">
			          		<img class="trust-logo noselect" src="../../assets/logos/logo-yellowquill.png" alt="yellowquill" />
			          </div>
			          <div class="col-xs-6 col-sm-4 col-md-4  col-lg-4 trust-item pikangikum">
			          		<img class="trust-logo noselect" src="../../assets/logos/logo-pikangikum.png" alt="pikangikum" />
			          </div>
			      </div>

			  </div>
			</div>