import { Component, OnInit } from '@angular/core';
import { Project } from '../project.model'; 
import { ProjectDataService } from '../../project-data.service';
import { Router, NavigationEnd, ActivatedRoute } from '@angular/router';
import { Location } from '@angular/common';
import { trigger, state, transition, style, animate  } from '@angular/animations';



@Component({
  selector: 'app-projects',
  templateUrl: './projects.component.html',
  styleUrls: ['./projects.component.css'],
  animations: [
  	trigger('animationState',[
  			state('start', style({
  				'transform': 'scale(1, 0)'
  			})),
  			state('end', style({
  				'transform':'scale(1, 1)'
  			})),
  			state('restart',style({
  				'transform':'scale(1,1)'
  			})),
 			transition('start <=> end', [animate(200)]),
 			transition('restart <=> *',[
 					animate(100,style({
 						'transform': 'scale(1,0)'
 					})),
 					animate(100, style({
 						'transform': 'scale(1,1)'
 					}))
 				])
      ])

  ]  
 
})

export class ProjectsComponent implements OnInit {

  projects : Project[] = [];
  tags = [];

  commercial_tag = "col-xs-6  col-sm-4 col-md-2  col-lg-2 project-item";
  industrial_tag = "col-xs-6  col-sm-4 col-md-2  col-lg-2 project-item";
  residential_tag = "col-xs-6  col-sm-4 col-md-2  col-lg-2 project-item";
  firstnation_tag = "col-xs-6  col-sm-4 col-md-2  col-lg-2 project-item";
  community_tag = "col-xs-6  col-sm-4 col-md-2  col-lg-2 project-item";

  commercial = false;
  industrial = false;
  residential = false;
  firstnation = false;
  community = false;

  state = 'start';


  private fragment: string;

  constructor(	private projectDataService:ProjectDataService, 
  				private route:ActivatedRoute,
  				private router: Router) {

  }

	containsActiveTags(element_tags:string[]){ //returns true if matching tags are turned on
		if(this.tags.length > 0){
			return findCommonElements(element_tags, this.tags);
		} else {
			return findCommonElements(["featured"], element_tags);
		}
	}


	onReAnimate(){
		//when you change tags, we want to go from state 'end' to 'start', then immediately from 'start' to 'end'
		// this.state != 'restart'? this.state = 'restart':this.state = 'end';
		this.state = 'restart';
	}

	getPj(project_number:string){
		return "#" + project_number;
	}

	getSrc(project_number: string, image_number:number){
		return "../assets/projects/" + project_number + "/hero-" + image_number + ".jpg";
	}

	getSrcSet(project_number:string, image_number:number){
		var set = 	"../assets/projects/" + project_number + "/hero-" + image_number + "-sm.jpg 500w" 
					+ ", " + 
					"../assets/projects/" + project_number + "/hero-"+ image_number + "-md.jpg 800w" 
					+ ", " + 
					"../assets/projects/" + project_number + "/hero-"+ image_number + ".jpg 1200w";
		return set;
	}


  navigateToProject(id:number){
  	//navigate to project/id page
  	this.router.navigate(['Project', id]);
  }

  ngOnInit(): void {
  	this.projects = this.projectDataService.sortedProjects;
	this.route.fragment.subscribe(fragment => {this.fragment = fragment; })
	// document.body.scrollTop = 0;
  }

  ngAfterViewInit():void {
	setTimeout( () => {
	    this.state = 'end';
		// console.log("animate!");	 
		document.body.scrollTop = 0;   
	}, 50);

	try {
		document.querySelector('#' + this.fragment).scrollIntoView();
	} catch (e){}
  }




    
  clickTag(tag: string){
	    	if (this.tags.includes(tag)){ //if the clicked tag already exists
	    		var i = this.tags.indexOf(tag); //remove it
	    		if (i > -1){
	    			this.tags.splice(i, 1);
	    		}
	    	} else {
		    	this.tags.push(tag); //if it doesn't, add it
	    	}

		switch(tag) {   		
		    case 'residential':
		    	this.residential = !this.residential;
		        break;
		    case 'commercial':
		    	this.commercial = !this.commercial;
		        break;
		    case 'industrial':
		    	this.industrial = !this.industrial;
		        break;
		    case 'community':
		    	this.community = !this.community;
		        break;
		    case 'firstnation':
		    	this.firstnation = !this.firstnation;
		        break;
		    default:
		        console.log("what is it?");
		}		  


	  	if (this.residential == true){ this.residential_tag = "col-xs-6  col-sm-4 col-md-2  col-lg-2 project-item selected"; } 
	  	else { this.residential_tag = "col-xs-6  col-sm-4 col-md-2  col-lg-2 project-item " ; }

	  	if (this.commercial == true){ this.commercial_tag = "col-xs-6  col-sm-4 col-md-2  col-lg-2 project-item selected"; } 
	  	else { this.commercial_tag = "col-xs-6  col-sm-4 col-md-2  col-lg-2 project-item " ; }

	  	if (this.industrial == true){ this.industrial_tag = "col-xs-6  col-sm-4 col-md-2  col-lg-2 project-item selected"; } 
	  	else { this.industrial_tag = "col-xs-6  col-sm-4 col-md-2  col-lg-2 project-item " ; }

	  	if (this.community == true){ this.community_tag = "col-xs-6  col-sm-4 col-md-2  col-lg-2 project-item selected"; } 
	  	else { this.community_tag = "col-xs-6  col-sm-4 col-md-2  col-lg-2 project-item " ; }

	  	if (this.firstnation == true){ this.firstnation_tag = "col-xs-6  col-sm-4 col-md-2  col-lg-2 project-item selected"; } 
	  	else { this.firstnation_tag = "col-xs-6  col-sm-4 col-md-2  col-lg-2 project-item " ; }


	//animate the new images  	
	this.onReAnimate();
  }
}

	function findCommonElements(array1, array2){
		return array1.some(item => array2.includes(item))
	}			
