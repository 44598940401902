import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { Routes, RouterModule } from '@angular/router';

import { AppComponent } from './app.component';
import { ServerComponent } from './server/server.component';
import { NavbarComponent } from './navbar/navbar.component';
import { FooterComponent } from './footer/footer.component';
import { SplashComponent } from './splash/splash.component';
import { TaoComponent } from './tao/tao.component';
import { ProjectsComponent } from './projects/projects/projects.component';
import { MainHeaderComponent } from './main/main-header/main-header.component';
import { TrustComponent } from './trust/trust.component';
import { TeamComponent } from './team/team.component';
import { ServicesHeaderComponent } from './services/services-header/services-header.component';
import { ItemsComponent } from './services/items/items.component';
import { WorkflowComponent } from './services/workflow/workflow.component';
import { DetailsComponent } from './services/details/details.component';
import { ProjectComponent } from './projects/project/project.component';
import { ProjectFeaturedComponent } from './projects/project-featured/project-featured.component';
import { ProjectsHeaderComponent } from './projects/projects-header/projects-header.component';
import { ServicesComponent } from './services/services/services.component';
import { HomeComponent } from './main/home/home.component';
import { CompanyComponent } from './company/company/company.component';
import { ContactComponent } from './contact/contact/contact.component';
import { ProjectDataService } from './project-data.service';
import { AboutUsComponent } from './company/about-us/about-us.component';
import { CompanySplashComponent } from './company/company-splash/company-splash.component';
import { ContactSplashComponent } from './contact/contact-splash/contact-splash.component';
import { ContactUsComponent } from './contact/contact-us/contact-us.component';
import { ContactFormComponent } from './contact/contact-form/contact-form.component';
import { EnquiryComponent } from './contact/enquiry/enquiry.component';
import { SplashBComponent } from './splash-b/splash-b.component';
import { SplashCComponent } from './splash-c/splash-c.component';
import { SplashDComponent } from './splash-d/splash-d.component';
import { ReturnTopComponent } from './projects/return-top/return-top.component';
import { ReturnToProjectsComponent } from './projects/return-to-projects/return-to-projects.component';
import { MobileMenuComponent } from './main/mobile-menu/mobile-menu.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { JobsComponent } from './jobs/jobs/jobs.component';
import { JobsSplashComponent } from './jobs/jobs-splash/jobs-splash.component';
import { ListingComponent } from './jobs/listing/listing.component';
import { SplashJobsComponent } from './splash-jobs/splash-jobs.component';

// import { FontAwesomeModule, FaIconLibrary } from '@fortawesome/angular-fontawesome';
// import { faTwitter } from '@fortawesome/free-solid-svg-icons';

const appRoutes: Routes = [
  { path: '', component: HomeComponent },
  { path: 'Services', component: ServicesComponent },
  { path: 'Projects', component: ProjectsComponent },
  { path: 'Project/:id', component: ProjectComponent },
  { path: 'Company', component: CompanyComponent },
  { path: 'Jobs', component: JobsComponent },
  { path: 'Contact', component: ContactComponent },
  { path: 'MobileMenu', component: MobileMenuComponent }

];

@NgModule({
  declarations: [
    AppComponent,
    ServerComponent,
    NavbarComponent,
    FooterComponent,
    SplashComponent,
    TaoComponent,
    ProjectsComponent,
    MainHeaderComponent,
    TrustComponent,
    TeamComponent,
    ServicesHeaderComponent,
    ItemsComponent,
    WorkflowComponent,
    DetailsComponent,
    ProjectComponent,
    ProjectFeaturedComponent,
    ProjectsHeaderComponent,
    ServicesComponent,
    HomeComponent,
    CompanyComponent,
    ContactComponent,
    AboutUsComponent,
    CompanySplashComponent,
    ContactSplashComponent,
    ContactUsComponent,
    ContactFormComponent,
    EnquiryComponent,
    SplashBComponent,
    SplashCComponent,
    SplashDComponent,
    ReturnTopComponent,
    ReturnToProjectsComponent,
    MobileMenuComponent,
    JobsComponent,
    JobsSplashComponent,
    ListingComponent,
    SplashJobsComponent
  ],
  imports: [
    BrowserModule,
    // FontAwesomeModule,
    FormsModule,
    BrowserAnimationsModule,
    RouterModule.forRoot(appRoutes)
  ],
  providers: [ProjectDataService],
  bootstrap: [AppComponent]
})
export class AppModule {
  // constructor(private library: FaIconLibrary){
  // library.addIcons(faTwitter);
  // }
}
