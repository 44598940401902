<div class="main-layout bk-grey sendtofront">
  <div class="container">
    <nav class="navbar navbar-static-top">
      <div class="navbar-header">
        <a class="navbar-left" data-spy="" routerLink="/"><img class="logo" src='/assets/VA_logo.png' /></a>

        <button type="button" class="navbar-toggle pointer hamburger" routerLink="/MobileMenu">
          <img class="" src="/assets/menu-lines.svg" />
        </button>
      </div>


      <div class="collapse navbar-collapse" id="collapsemenu">
        <ul class="nav navbar-nav navbar-right">

          <li><a routerLink="/Projects">Projects</a></li>
          <li><a routerLink="/Services">Services</a></li>
          <li><a routerLink="/Company">Company</a></li>
          <li><a routerLink="/Jobs">Jobs</a></li>
          <li><a routerLink="/Contact">Contact Us</a></li>

          <li class="pad-right"></li>
        </ul>
      </div>
    </nav>
  </div>


</div>