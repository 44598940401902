<div  class="jumbotron return_splash bk-white close-gap-bottom ">
	<div class="container  return_up">
		<div class="row ">
			<div class="col-md-12 section-tagheader">
			      <a  (click)="clicker()" [routerLink]='"."' fragment="projects_top">
			      	<h2 class="roboto-medium black  text-right"><img class="" src="../../assets/arrow_left_up.png" alt="arrow" /> <em class="return-top noitalics">Return</em></h2></a>
			</div>	
		    <div class="col-md-12 white hidden-md hidden-lg spacer"></div>					
		    <div class="col-md-12 white hidden-md hidden-lg spacer"></div>					
		    <div class="col-md-12 white hidden-md hidden-lg spacer"></div>					

		</div>
	</div>
</div>