import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-splash-b',
  templateUrl: './splash-b.component.html',
  styleUrls: ['./splash-b.component.css']
})
export class SplashBComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
