import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class ProjectDataService {
  folder = [];
  folderForSetMed = [];
  folderForSetSm = [];
  folderForSetLg = [];
  folderSizes = "(min-width: 1200px) 1200px, 100vw";

  projects = [
	  {
		id:1,
		title: 'Voitec architect office',
		status: 'Completed',
		year: 2001, 
		location: 'Winnipeg',
		address: '516 St.Catherine',
		budget: 'n/a',
		area: 'n/a',
		lot_area:'n/a',
		unit_area:'n/a',
		client: 'Voitec Architect Inc.',
		description: 'Located on the banks of the Seine river, this two and a half storey building serves as both a residential home with a large yard as well as the office of Voitec Architect.',
		description_word_count: 0,
		project_number: '00-00',
		tags: ['residential', 'commercial'],
		featured: false,

	  },
	  {
		id:2,
		title: 'Morier triplex',
		status: 'Completed',
		year: 2013, 
		location: 'Winnipeg, MB',
		address: '139 Morier Ave.',
		budget: '',
		area: '3,000',
		lot_area:'75 x 100',
		unit_area:'900 - 1,000',
		client: 'Private',
		description: 'Nestled on a quiet street behind mature pine trees against the Seine River in Winnipeg\’s historic St. Boniface, this three storey triplex was originally a non-descript one-storey bungalow. Remodelling of the kitchen and bathroom of the 98-year-old house was completed and the foundation replaced. The wood framed walls and floor were saved and reused and the entire house was raised to 5 feet above grade and a new ICF basement was constructed. The original 4\"x12\” solid wood beams were reused to frame the second story porch. Each unit occupies an entire floor of the triplex and each contains 2 bedrooms and in-suite laundry. The oversized windows combined with deep 25 feet side setbacks allow a generous amount of light to penetrate all three levels.',
		description_word_count: 0,
		project_number: '00-13',
		tags: ['residential','featured'],
		featured: false
	  },
	  {
		id:3,
		title: 'YellowQuill College',
		status: 'Completed',
		year: 2010, 
		location: 'Winnipeg, MB',
		address: '480 Madison St.',
		budget: '',
		area: '',
		lot_area:'',
		unit_area:'',
		client: 'YellowQuill College',
		description: 'Yellowquill College called upon Voitec Architect Inc. to design a new vision for a unified campus. Common areas were designed to becentrally located with orientation that reflects the school\'s core values of expectional educational opportunities and the nurturing of a sense of identity and connection with the past. With flexible and social areas, students are provided active space outside the classroom for collaborative learning, exploration and discovery.',
		description_word_count: 0,
		project_number: '05-10',
		tags: [ 'firstnation', 'community'],
		featured: false
	  },
	   {
		id:4,
		title: 'St.Vital Pool',
		status: 'Completed',
		year: 2014, 
		location: 'Winnipeg, MB',
		address: '3 Des Meurons Ave',
		budget: '',
		area: '',
		lot_area:'',
		unit_area:'',
		client: 'City of Winnipeg',
		description: 'The St. Vital Pool site is located in Winnipeg at the south terminus of Des Meurons Street and north of Fermor Avenue and is designed to serve as a partnering facility to the existing outdoor swimming pool. The building includes washrooms, showers, changing rooms, locker rooms, administration room, staff rooms, first aid room, guard office, storage room, mechanical room, as well as outdoor showers. The design of the building strongly supports the sense of place and community at the already existing pool site, through enhancing the ease of use, safety and comfort. Careful consideration has been given to access routes and drop off zones, entry experiences, public desire lines and patterns of use, maintenance operations, bike parking, views and edges, staff areas, and supervision sight lines, to create and overall environment that supports pool staff in providing an exceptional outdoor pool experience.',
		description_word_count: 0,
		project_number: '09-14',
		tags: [ 'community', 'featured'],
		featured: false
	  },
	   {
		id:5,
		title: 'Holy Ghost School',
		status: 'Completed',
		year: 2007, 
		location: 'Winnipeg, MB',
		address: '319 Selkirk Ave',
		budget: '1.4M',
		area: '10,000',
		lot_area:'100 x 100',
		unit_area:'',
		client: 'Holy Ghost Parish',
		description: 'The project located on 319 Selkirk Ave, Winnipeg, was a renovation of an existing building, as well as a new building addition. The renovation included both the envelope and interior space of the existing building. The addition included the design of new classrooms, computer room, gathering space, and library. The design was comprised of various social, educational and religious aspects.',
		description_word_count: 0,
		project_number: '14-07',
		tags: [ 'community'],
		featured: false
	  },	  
	   {
		id:6,
		title: 'Victoria Beach Cottage',
		status: 'Completed',
		year: 2008, 
		location: 'Victoria Beach, MB',
		address: 'Zeglinski Bay',
		budget: '',
		area: '1,422',
		lot_area:'',
		unit_area:'',
		client: 'Private',
		description: 'This contemporary cottage is comprised of a seasonal portion and a year-round portion, both of which are cladded in cedar shakes. The seasonal section is a self-contained studio – with its own kitchenette, bathroom and storage space – while the year-round, winterized portion has two bedrooms. Both portions of the cottage are connected by a screened room/summer living room, and were placed in existing clearings on the property in order to preserve the surrounding beautiful forest. Extreme care was also taken to accommodate existing trees by cutting out roof overhangs where it was appropriate. Additionally, the concrete  floor slab on grade is heated all throughout, and the winterized portion utilizes wood timber construction for the living room area with glass walls on all sides.',
		description_word_count: 0,
		project_number: '17-01',
		tags: [ 'residential', 'featured'],
		featured: false
	  },	
	 {
		id:7,
		title: 'Matix Lumber Building',
		status: 'Completed',
		year: 2013, 
		location: 'Winnipeg, MB',
		address: '4987 Portage Ave West',
		budget: '',
		area: '',
		lot_area:'',
		unit_area:'',
		client: 'Matix Lumber',
		description: 'Commercial building designed for Matix Lumber.',
		description_word_count: 0,
		project_number: '39-13',
		tags: [ 'commercial'],
		featured: false
	  },	
	 {
		id:8,
		title: 'Bearskin Lake Nursing Station',
		status: 'Completed',
		year: 2001, 
		location: 'Bearskin Lake First Nation',
		address: '',
		budget: '',
		area: '',
		lot_area:'',
		unit_area:'',
		client: 'Bearskin Lake First Nation',
		description: 'This beautiful facility provides increased ambulatory space for outpatient/community programming in a setting that defines the salutogenic approach to healthcare in Bearskin Lake First Nation. Connecting with nature and the surrounding community, the facility provides abundant natural light and spectacular views of Bearskin Lake.',
		description_word_count: 0,
		project_number: '22-01',
		tags: [ 'community', 'firstnation'],
		featured: false
	  }, 
	 {
		id:9,
		title: 'KI Bowling Alley',
		status: 'Completed',
		year: 2006, 
		location: 'Big Trout',
		address: '',
		budget: '',
		area: '',
		lot_area:'',
		unit_area:'',
		client: 'Kitchenuhmaykoosib Inninuwug',
		description: 'This building houses a cafe/bar and bowling alley as well as an arcade.',
		description_word_count: 0,
		project_number: '32-06',
		tags: [ 'community', 'firstnation'],
		featured: false
	  }, 
	 {
		id:10,
		title: 'Wellington Residence',
		status: 'Completed',
		year: 2007, 
		location: 'Winnipeg, MB',
		address: 'Wellington Crescent',
		budget: '',
		area: '',
		lot_area:'',
		unit_area:'',
		client: 'Kulak Family',
		description: 'Beautifully located on the Red River.',
		description_word_count: 0,
		project_number: '23-07',
		tags: [ 'residential'],
		featured: false
	  },  
	 {
		id:11,
		title: 'Pikangikum Community Centre',
		status: 'Completed',
		year: 2007, 
		location: 'Pikangikum, ON',
		address: '',
		budget: '1M',
		area: '',
		lot_area:'',
		unit_area:'',
		client: 'Pikangikum First Nation',
		description: 'The project was conceived and designed based on the feasibility study done by VOITEC Architect and is providing early childhood learning and development for a range of young children, including special needs children. The project includes a mini-gym space for physical activities along with an outdoor playground with an enclosing fence, as well as parental education room for various workshops provided from Health Canada and community themselves. The portion of the project is rented out to businesses in order to diversify the community members using the spaces ensuring active atmosphere despite the small population. All amenities are complementary and help support the Centre by attracting a cross-section of the community as well as out-of-town visitors.',
		description_word_count: 0,
		project_number: '22-07',
		tags: [ 'community', 'firstnation'],
		featured: false
	  }, 
	 {
		id:12,
		title: 'North Caribou Hockey Arena',
		status: 'Completed',
		year: 2009, 
		location: 'Weagamow Lake, ON',
		address: '',
		budget: '',
		area: '',
		lot_area:'',
		unit_area:'',
		client: 'North Caribou First Nation',
		description: 'The North Caribou Arena is a hockey arena located in at Weagamow Lake and was designed with focus on flexible us of space, community activites and organized sports. The Arena needed to be more than just a hockey rink.',
		description_word_count: 0,
		project_number: '04-09',
		tags: [ 'community', 'firstnation'],
		featured: false
	  }, 
	 {
		id:13,
		title: 'Bearskin Lake Arena',
		status: 'Completed',
		year: 2009, 
		location: 'Bearskin Lake, ON',
		address: '',
		budget: '',
		area: '',
		lot_area:'',
		unit_area:'',
		client: 'Bearskin Lake First Nation',
		description: 'The Bearskin Lake Arena serves its community throughout the year. The building houses large rink, canteen, and change rooms complete with bathrooms.',
		description_word_count: 0,
		project_number: '10-09',
		tags: [ 'community', 'firstnation'],
		featured: false
	  }, 
	 {
		id:14,
		title: 'U of M Generators',
		status: 'Completed',
		year: 2010, 
		location: 'Winnipeg, MB',
		address: '',
		budget: '150K',
		area: '4,000',
		lot_area:'6,000',
		unit_area:'',
		client: 'University of Manitoba',
		description: 'Located on the University of Manitoba Fort Garry Campus, these two buildings house emergency generators. Constructed in 2011, these 1300 sq. ft. buildings were designed with function, economics and aesthetics in mind. By working closely with structural and mechanical engineers, the building functions efficiently with a reduced building footprint. Exterior finishing materials were chosen for durability, low maintenance and aesthetic qualities. In particular exterior colours and finishes (notably the tyndall stone and red brick) were chosen to blend into the surrounding campus buildings.',
		description_word_count: 0,
		project_number: '09-10',
		tags: [ 'industrial'],
		featured: false
	  }, 
	 {
		id:15,
		title: 'Fort Severn Police Station',
		status: 'Completed',
		year: 2010, 
		location: 'Fort Severn, ON',
		address: '',
		budget: '',
		area: '',
		lot_area:'',
		unit_area:'',
		client: '',
		description: '',
		description_word_count: 0,
		project_number: '16-10',
		tags: [ 'community', 'firstnation'],
		featured: false
	  }, 
	 {
		id:16,
		title: 'Slate Falls Hockey Arena',
		status: 'Completed',
		year: 2013, 
		location: 'Slate Falls, ON',
		address: '',
		budget: '',
		area: '',
		lot_area:'',
		unit_area:'',
		client: '',
		description: 'This arena in Slate falls was designed as an indoor hockey venue which can host games in front of a capacity of up to ??? people. The purpose behind the structure will be to improve local sports infrasctructure for the community and to foster young talent. It was important to create a flexible multipurpose arena with a strong identity while respoecting the history and vision of the area.',
		description_word_count: 0,
		project_number: '13-13',
		tags: [ 'community', 'firstnation'],
		featured: false
	  }, 
	 {
		id:17,
		title: 'Waterford Green Townhomes',
		status: 'Completed',
		year: 2014, 
		location: 'Winnipeg, MB',
		address: 'Jefferson Ave',
		budget: '',
		area: '',
		lot_area:'',
		unit_area:'',
		client: '',
		description: 'Waterford Green, northwest Winnipeg\’s newest community, puts forward a new standard of community planning that emphasizes quality living and connectivity. Its diverse community features housing types joined with parks, green spaces, shopping, public transit, schools, and adjoining neighbourhoods. Waterford Green\’s housing options and housing market price points, provide choice and diversity reflective housing needs.',
		description_word_count: 0,
		project_number: '20-13',
		tags: [ 'residential', 'featured'],
		featured: false
	  }, 
	 {
		id:18,
		title: 'Whitesand Multiplex',
		status: 'Completed',
		year: 2014, 
		location: 'Whitesand, ON',
		address: '12 Princess St.',
		budget: '1.3M',
		area: '',
		lot_area:'',
		unit_area:'',
		client: '',
		description: 'The multiplex in Whitesand is a combination of assembly occupancy and business and personal services occupancy. The project includes a gym and office building that shares facilities such as washrooms and a cafeteria.',
		description_word_count: 0,
		project_number: '44-14',
		tags: [ 'community', 'firstnation'],
		featured: false
	  }, 
	 {
		id:19,
		title: 'Long Plain Police Station',
		status: 'Completed',
		year: 2015, 
		location: 'Portage la Prairie, MB',
		address: 'Yellow Quill Trail',
		budget: '',
		area: '',
		lot_area:'',
		unit_area:'',
		client: '',
		description: '',
		description_word_count: 0,
		project_number: '05-15',
		tags: [ 'community', 'firstnation'],
		featured: false
	  }, 
	 {
		id:20,
		title: 'Ben & Florentine',
		status: 'Completed',
		year: 2015, 
		location: 'Winnipeg, MB',
		address: '155 Scurfield Blvd.',
		budget: '',
		area: '',
		lot_area:'',
		unit_area:'',
		client: 'Ben & Florentine',
		description: 'Commercial Building designed for Ben & Florentine.',
		description_word_count: 0,
		project_number: '10-15',
		tags: [ 'commercial' ],
		featured: false
	  }, 
	 {
		id:21,
		title: 'Wendy\'s Restaurant',
		status: 'Completed',
		year: 2016, 
		location: 'Winnipeg, MB',
		address: '',
		budget: '',
		area: '',
		lot_area:'',
		unit_area:'',
		client: 'Wendy\'s',
		description: 'Voitec Architect worked with Wendy\’s to incorporate the latest design standards and trademarks. This included the \"Red Blade\" design that was incorporated throughout Wendy\’s restaurants nationwide. Voitec Architect\'s approach in this redesign was to work directly with civil engineers to site adapt Wendy\’s standard design help with zoning & building departments to accommodate local building site & finish requirements as well as field surveys, design, construction documents, permitting, rendering and construction administration.',
		description_word_count: 0,
		project_number: '07-16',
		tags: [ 'commercial', 'featured'],
		featured: false
	  }, 
	 {
		id:22,
		title: '4-Trax Office Addition',
		status: 'Completed',
		year: 2016, 
		location: 'Rosser, MB',
		address: '374 Eagle Dr.',
		budget: '',
		area: '7,000',
		lot_area:'',
		unit_area:'',
		client: 'MEHTOJ Groud Ltd.',
		description: 'Located in CentrePort Canada in the R.M. of Rosser, the original building built in 2011 contained a 3173.3 sq. ft. 2-bay garage and a 1626.7 sq. ft. office area. The building features approximately 3800 sq. ft. of office space on two storeys. By carefully observing and understanding the daily operations of the company, the design caters to the specific needs of the company. Working closely together with the owner and general contractor throughout the design and construction of the project was imperative to ensure the project could proceed on schedule and on budget with minimal disruptions to the daily operations of the company.',
		description_word_count: 0,
		project_number: '23-15',
		tags: [ 'commercial'],
		featured: false
	  }, 
	 {
		id:23,
		title: 'Long Plain Gas Station',
		status: 'Completed',
		year: 2017, 
		location: 'Winnipeg, MB',
		address: '490 Madison St.',
		budget: '',
		area: '',
		lot_area:'',
		unit_area:'',
		client: 'Long Plain First Nation',
		description: '',
		description_word_count: 0,
		project_number: '15-17',
		tags: [ 'commercial', 'firstnation'],
		featured: false
	  }, 
	 {
		id:24,
		title: 'Mehtoj Group Garage',
		status: 'Completed',
		year: 2013, 
		location: 'Rosser, MB',
		address: '310 Eagle Dr.',
		budget: '',
		area: '',
		lot_area:'',
		unit_area:'',
		client: 'MEHTOJ Group Ltd.',
		description: '',
		description_word_count: 0,
		project_number: '32-06',
		tags: [ 'Commercial' ],
		featured: false
	  }, 
	 {
		id:25,
		title: 'Agri-Fresh Office',
		status: 'Completed',
		year: 2016, 
		location: 'Lockport, MB',
		address: '100 IXL Crescent',
		budget: '900K',
		area: '5,500',
		lot_area:'',
		unit_area:'',
		client: 'Agri-Fresh Inc.',
		description: '',
		description_word_count: 0,
		project_number: '26-16',
		tags: [ 'commercial' ],
		featured: false
	  } ,
	  {
		 id:26,
		 title: 'Viking Cabin',
		 status: 'Completed',
		 year: 2020, 
		 location: 'Riverton, MB',
		 address: 'Lake Winnipeg',
		 budget: '-',
		 area: '280 sq ft',
		 lot_area:'infinite',
		 unit_area:'280 sq ft',
		 client: 'M.Myrowich',
		 description: 'An A-frame style mobile Viking Ice Cottage with indoor facilites and a wood stove. It has floor to ceiling windows and a blue and white striped roof. It can be moved on and off the ice and serves as a wonderful mini nature hotel.',
		 description_word_count: 0,
		 project_number: '58-20',
		 tags: [ 'residential', 'commercial', 'featured'],
		 featured: true
	   } 
	 // {
		// id:27,
		// title: 'Alley',
		// status: 'Completed',
		// year: 2006, 
		// location: '',
		// address: '',
		// budget: '',
		// area: '',
		// lot_area:'',
		// unit_area:'',
		// client: '',
		// description: '',
		// description_word_count: 0,
		// project_number: '32-06',
		// tags: [ 'community', 'firstnation'],
		// featured: false
	 //  }, 
	 // {
		// id:28,
		// title: 'Alley',
		// status: 'Completed',
		// year: 2006, 
		// location: '',
		// address: '',
		// budget: '',
		// area: '',
		// lot_area:'',
		// unit_area:'',
		// client: '',
		// description: '',
		// description_word_count: 0,
		// project_number: '32-06',
		// tags: [ 'community', 'firstnation'],
		// featured: false
	 //  }, 
	 // {
		// id:29,
		// title: 'Alley',
		// status: 'Completed',
		// year: 2006, 
		// location: '',
		// address: '',
		// budget: '',
		// area: '',
		// lot_area:'',
		// unit_area:'',
		// client: '',
		// description: '',
		// description_word_count: 0,
		// project_number: '32-06',
		// tags: [ 'community', 'firstnation'],
		// featured: false
	 //  }, 
	 // {
		// id:30,
		// title: 'Alley',
		// status: 'Completed',
		// year: 2006, 
		// location: '',
		// address: '',
		// budget: '',
		// area: '',
		// lot_area:'',
		// unit_area:'',
		// client: '',
		// description: '',
		// description_word_count: 0,
		// project_number: '32-06',
		// tags: [ 'community', 'firstnation'],
		// featured: false
	 //  }, 
	 // {
		// id:31,
		// title: 'Alley',
		// status: 'Completed',
		// year: 2006, 
		// location: '',
		// address: '',
		// budget: '',
		// area: '',
		// lot_area:'',
		// unit_area:'',
		// client: '',
		// description: '',
		// description_word_count: 0,
		// project_number: '32-06',
		// tags: [ 'community', 'firstnation'],
		// featured: false
	 //  }, 
	 // {
		// id:32,
		// title: 'Alley',
		// status: 'Completed',
		// year: 2006, 
		// location: '',
		// address: '',
		// budget: '',
		// area: '',
		// lot_area:'',
		// unit_area:'',
		// client: '',
		// description: '',
		// description_word_count: 0,
		// project_number: '32-06',
		// tags: [ 'community', 'firstnation'],
		// featured: false
	 //  }, 
  ];

  sortedProjects = this.projects.sort((a,b)=>b.year - a.year);

  getImageSrcSet(project_number:string){


  	// sm -> "../assets/projects/" + project_number + "/hero-" + i + "-sm.jpg 500w";
  	// md -> "../assets/projects/" + project_number + "/hero-" + i + "-md.jpg 800w";
  	// lg -> "../assets/projects/" + project_number + "/hero-" + i + "-lg.jpg 1200w";


//these have to be stuffed into a single string

	// sm + ", " + md + ", " + lg
  	return "nerp";
  }

  getImageSrc(project_number: string){

  	//we pass in the project number and size
  	return "nerp + " + project_number;
  } 

  constructor() { 

  	// for (var i = 1; i <= 4; i++) {
  	// 	this.folder[i] = "../assets/projects/" + this.projects
  	// }


  }
}
