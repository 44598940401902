
<!-- TAGS -->

<div id="projects_top" class="jumbotron projects_showcase bk-white close-gap-bottom over-z top-shadow-black">
	<div class="container ">

		<a name="projects_splash"></a>

	    <div id="tagbar" class=" row close-gap-top ">
	        <div  class="hidden-xs  hidden-sm col-md-1 col-lg-1 project-item"></div>			    
	        <div 

		        (click)="clickTag('residential')"
		        ref="residential" 
		        class="{{residential_tag}}"
		        value="residential">
	          		
          		<img class="project-logo noselect" src="../../assets/logos/res_logo.png" alt="Residential" />

          		<h3 class="use_type noselect">Residential</h3>
	        </div>
	        <div  
        		(click)="clickTag('commercial')"
        		ref="commercial"  
        		class="{{this.commercial_tag}}"
        		value="commercial"> 

          		<img class="project-logo noselect" src="../../assets/logos/com_logo.png" alt="Commercial" />

          		<h3 class="use_type noselect">Commercial</h3>
	        </div>
	        <div 
	        		(click)="clickTag('industrial')"
	        		ref="industrial"  
	        		class="{{this.industrial_tag}}"
	          		value="industrial">

	          		<img class="project-logo noselect" src="../../assets/logos/ind_logo.png" alt="Industrial" />
	          		<h3 class="use_type noselect">Industrial</h3>
	        </div>
	        <div 
	        		(click)="clickTag('community')"
	        		ref="community"  
	        		class="{{this.community_tag}}"
	          		value="community">

	          		<img class="project-logo noselect" src="../../assets/logos/ins_logo.png" alt="community" />
	          		<h3 class="use_type noselect">Community</h3>
	        </div>			          
	        <div 
	        		(click)="clickTag('firstnation')"
	        		ref="firstnation"  
	        		class="{{this.firstnation_tag}}"
					value="firstnation">

	          		<img class="project-logo noselect" src="../../assets/logos/fn_logo.png" alt="First nation" />
	          		<h3 class="use_type noselect">First Nation</h3>
	        </div>

	        <div class="hidden-xs  hidden-sm col-md-1 col-lg-1 project-item"></div>

		</div>

	    <div id="replacement_tagbar" class="hidden row close-gap-top ">
	    	<div  class="hidden-xs  hidden-sm col-md-1 col-lg-1 project-item"></div>			    
	    	<div 
	        		(click)="clickTag('residential')"
	        		ref="residential" 
	        		class="{{this.residential_tag}}"
	        		value="residential">

	          		<img class="project-logo noselect" src="../../assets/logos/res_logo.png" alt="Residential" />
	          		<h3 class="use_type noselect">Xesidential</h3>
	        </div>
	        <div  
	        		(click)="clickTag('commercial')"
	        		ref="commercial"  
	        		class="{{this.commercial_tag}}"
	        		value="commercial">

	          		<img class="project-logo noselect" src="../../assets/logos/com_logo.png" alt="Commercial" />
	          		<h3 class="use_type noselect">Xommercial</h3>
	        </div>
	        <div 	
	        		(click)="clickTag('industrial')"
	        		ref="industrial"  
	        		class="{{this.industrial_tag}}"
					value="industrial">

	          		<img class="project-logo noselect" src="../../assets/logos/ind_logo.png" alt="Industrial" />
	          		<h3 class="use_type noselect">Xndustrial</h3>
	        </div>
	        <div 	
	        		(click)="clickTag('community')"
	        		ref="community"  
	        		class="{{this.community_tag}}"
	        		value="community">

	          		<img class="project-logo noselect" src="../../assets/logos/ins_logo.png" alt="community" />
	          		<h3 class="use_type noselect">Xommunity</h3>
	        </div>			          
	        <div 	
	        		(click)="clickTag('firstnation')"
	        		ref="firstnation"  
	        		class="{{this.firstnation_tag}}"
					value="firstnation">

	          		<img class="project-logo noselect" src="../../assets/logos/fn_logo.png" alt="First nation" />
	          		<h3 class="use_type noselect">Xirst Nation</h3>
	        </div>
	        <div class="hidden-xs  hidden-sm col-md-1 col-lg-1 project-item"></div>
	    </div>			      
	     
	</div>
			  	
	<!-- PROJECTS GO HERE -->

	<div *ngFor="let proj of projects">
	  	<div class="container spacer bk-white" *ngIf="containsActiveTags(proj.tags)">
	  		<div class="row"  >
    			<div class="  col-xs-12 col-sm-12 col-md-12 col-lg-12  squish-animation"	[@animationState]="state">

					<!-- carousel  -->
					<div class="carousel slide" data-ride="carousel" id={{proj.project_number}}>

						<div class="carousel-inner">

							<!-- carousel item 1 -->
							<div  (click)="navigateToProject(proj.id)" class="carousel-item active pointer">
								<img class="main-slide" 	src="{{getSrc(proj.project_number, 1)}}" 
															srcSet="{{getSrcSet(proj.project_number, 1)}}" 
															sizes="(min-width: 1200px) 1200px, 100vw"
															alt="1 Photo"/>
								<div class="carousel-caption  align-left">
								<h4 class="carousel-title white paddless noselect bk-transparent-black">{{proj.title}}: {{proj.year}}</h4>
								  <p class="lead">
								    <a  (click)="navigateToProject(proj.id)" class="roboto-medium bluey btn btn-primary btn-lg hidden-xxs" id="details-button" role="button">Details ⇢ </a>
								  </p>
							    </div>
							</div>


						</div>

					</div>


				</div>
			</div>
		</div>
	</div>

			
		<app-return-top></app-return-top>


</div>
		
