			<div  class="jumbotron entice_splash bk-white close-gap-bottom">
				<div class="container">
					<div class="row  mobileMargin">
						<div class="col-md-12 section-tagheader">
						      <h2 class="roboto-medium black">Our approach<img class="hidden-xs" src="../../assets/tag-line.png" alt="" /></h2>
						</div>	
					    <div class="col-md-12 black hidden-md hidden-lg spacer">

					    </div>					
				 	<div class="row spacer">
					    <div class="col-md-5 black">
					    
					    </div>
					    <div class="col-md-6 text-block">
					      <p class="roboto black ">
								Our work responds to the needs and resources of the contemporary world. The buildings we produce come from a detailed understanding of the functions they must fulfill, the conditions they have to provide and the materials from which they are constructed. This understanding is directly translated into form and detail. <br/><br/>

								Our ultimate goal is to design buildings and environments that work, inspire people and transform communities. Through careful evaluation of relevant opportunities, inspired ideas will drive a good design to something that is extraordinary, challenging, and completely unique.					      </p>
					    </div>
					  </div>

				    </div>
				</div>
			</div>