import { Component } from '@angular/core';
import { ProjectDataService } from './project-data.service';


@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
  // providers:[ ProjectDataService ]
})
export class AppComponent {
  title = 'voitec-angular';
  // faTwitter = faTwitterSquare;
  // name = 'enter name';
}
