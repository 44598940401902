import { Component, OnInit } from '@angular/core';
import { Router, NavigationEnd, ActivatedRoute } from '@angular/router';
import { Location } from '@angular/common';


@Component({
  selector: 'app-details',
  templateUrl: './details.component.html',
  styleUrls: ['./details.component.css']
})
export class DetailsComponent implements OnInit {

  private fragment: string;

  constructor(private route:ActivatedRoute) {}


  ngOnInit(): void {
  	this.route.fragment.subscribe(fragment => {this.fragment = fragment; })
  }

  ngAfterViewInit():void {
  	try {
  		document.querySelector('#' + this.fragment).scrollIntoView();
  	} catch (e){}
  }

}
