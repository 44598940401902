<div id="splash_box" ref="scrollDOM" class="container bk-black">
	<div class=" jumbotron main_splash lines-bk bk-black row close-gap-bottom close-gap-top under-z  no-overflow">
		<div class="row no-pad no-margins ">

			<div class="main-splash-text col-lg-12 col-md-11 col-sm-11 col-xs-11 no-margins">
				<h4 class="lead-title ">We are a full-service architecture <br> and design studio. </h4>
				<p class="lead-p noselect">

					<a routerLink="/Projects" class="lead-tagline main_border_blue normal wider">Design</a> and <a
						routerLink="/Services" class="lead-tagline main_border_blue normal wider">Services</a><br
						class="hidden-lg hidden-md" /><em class="noitalics nexty-indent">for your next project</em>
				</p><br><br>

				<p class="lead ">
					<!-- <a class="action_button bluey btn btn-primary btn-lg " routerLink="/Jobs" role="button">We're -->
					<!-- Hiring - Click here for more </a> -->
					<br>
					<br>
					<br>



				</p>

			</div>
		</div>
	</div>
</div>