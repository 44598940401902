
	<div  class="jumbotron entice_splash bk-light-grey close-gap-bottom top-shadow-blue">
		<div class="container">
			<div id="services-splash"class="row ">
				<div class=" section-tagheader mobileMargin">
				      <h2 class="roboto-medium black mobileMargin">What we provide for clients<img class="hidden-xs" src="../../assets/tag-line.png" alt="arrow" /></h2>
				</div>	
			    <div class="col-md-12 white  spacer"></div>			
			    <div class="col-md-12 white  spacer"></div>				
			    <div class="col-md-12 white hidden-md hidden-lg spacer"></div>					

			    <div class="col-md-12 white hidden-md hidden-lg spacer"></div>					
			    <div class="col-md-12 white hidden-md hidden-lg spacer"></div>		


					<div class="card-group col-xs-12 col-sm-12 col-md-5 col-lg-5 ">

					        <div class="oss-item-header">
						          <h6 class="black">Design</h6>
					        </div>		

				        <div class="card-deck ">
							
								<div class="card col-xs-12 col-sm-6  ">
						          <a class="oss-link card-body" 
								          [routerLink]='"."' fragment="design"
						          > <!-- #design -->
								  <div class="card-header">
								    <h4 class="card-title">Schematic Design</h4>
								  </div>								          	
						            
							        <img class="card-img-top service-logo" src="../../assets/services/3d-model-icon-black-small.svg" alt="logo"/>
						            <p class="card-text">Whether or not you have design concept, we can tailor to your needs. We provide custom residential, commercial and institutional design from inside out.</p>

						          </a>
						            <div class="card-footer">
						            	<a 
								          [routerLink]='"."' fragment="design"
						            	class="roboto-medium orangey btn btn-primary btn-lg btn-full class-text"  role="button">Learn More</a>
						            </div>								          
						        </div>

								<div class="card col-xs-12 col-sm-6 ">
						          <a class="oss-link card-body"   
						          [routerLink]='"."' fragment="detailed-design"

						          >
								  <div class="card-header">
								     <h4 class="card-title">Detailed Design</h4>
								  </div>								          	
						           
							        <img class="card-img-top service-logo" src="../../assets/services/detailed-design-icon-black-small.svg" alt="logo"/>
						            <p class="card-text">Upon sharing a common design vision, our team of design professionals can deliver detailed drawings for construction and permit application.</p>
						            <div class="card-footer">
						            	<a  
							          [routerLink]='"."' fragment="detailed-design"
						            	class="roboto-medium orangey btn btn-primary btn-lg btn-full"  role="button">Learn More</a>
						            </div>
						          </a>

						        </div>
		        	
				        </div>
					</div>


					<div class="card-group col-xs-12 col-sm-12 col-md-7 col-lg-7 ">

					        <div class="oss-item-header">
						          <h6 class="black">Administration</h6>
					        </div>		

				        <div class="card-deck">
							
								<div class="card col-xs-12 col-sm-4  ">
						          <a class="oss-link card-body"   
								          [routerLink]='"."' fragment="administration"
						          >
								  <div class="card-header">
								    <h4 class="card-title">Tendering</h4>
								  </div>								          	
						            
							        <img class="card-img-top service-logo" src="../../assets/services/tender-icon-black-small.svg" alt="logo"/>
						            <p class="card-text">Whether open or invited tendering, our team can help you competitively and fairly tender your projects.</p>

						          </a>
						            <div class="card-footer">
						            	<a  
								          [routerLink]='"."' fragment="administration"
						            	class="roboto-medium orangey btn btn-primary btn-lg btn-full class-text"  role="button">Learn More</a>
						            </div>								          
						        </div>

								<div class="card col-xs-12 col-sm-4 ">
						          <a class="oss-link card-body"   
								          [routerLink]='"."' fragment="contract-administration"
						          >
								  <div class="card-header">
								     <h4 class="card-title">Contract Administration</h4>
								  </div>								          	
						           
							        <img class="card-img-top service-logo" src="../../assets/services/contract-icon-black-small.svg" alt="logo"/>
						            <p class="card-text">We will work with the contractor during project construction and keep you informed on the progress of construction.</p>
						            <div class="card-footer">
						            	<a  
								          [routerLink]='"."' fragment="contract-administration"
						            	class="roboto-medium orangey btn btn-primary btn-lg btn-full"  role="button">Learn More</a>
						            </div>
						          </a>

						        </div>

								<div class="card col-xs-12 col-sm-4 ">
						          <a class="oss-link card-body"   
						          
								          [routerLink]='"."' fragment="post-occupancy"

						          >
								  <div class="card-header">
								     <h4 class="card-title">Post Occupancy</h4>
								  </div>								          	
						           
							        <img class="card-img-top service-logo" src="../../assets/services/post-occupancy-icon-black-small.svg" alt="logo"/>
						            <p class="card-text">During the warranty period, our team is here to help answer any questions or concerns regarding your facility.</p>
						            <div class="card-footer">
						            	<a  
								          [routerLink]='"."' fragment="post-occupancy"

						            	  class="roboto-medium orangey btn btn-primary btn-lg btn-full"  role="button">Learn More</a>
						            </div>
						          </a>

						        </div>
		        	
				        </div>

					




					
					</div>

					<div class="card-group col-xs-12 col-sm-12 col-md-12 col-lg-12 ">

					        <div class="oss-item-header">
						          <h6 class="black">Planning and Studies</h6>
					        </div>		

				        <div class="card-deck ">
							
								<div class="card col-xs-12 col-sm-6 col-md-3 col-lg-3 ">
						          <a class="oss-link card-body"   
								          [routerLink]='"."' fragment="planning"

						          >
								  <div class="card-header">
								    <h4 class="card-title">Development & Spatial Planning</h4>
								  </div>								          	
						            
							        <img class="card-img-top service-logo" src="../../assets/services/spatial-icon-black-small.svg" alt="logo"/>
						            <p class="card-text">We can help you explore the potential of your undeveloped building site. We know Zoning Bylaws and applying for re-zoning, variances, and conditional uses. </p>

						          </a>
						            <div class="card-footer">
						            	<a  
								          [routerLink]='"."' fragment="planning"

						            	class="roboto-medium orangey btn btn-primary btn-lg btn-full class-text"  role="button">Learn More</a>
						            </div>								          
						        </div>

								<div class="card col-xs-12 col-sm-6 col-md-3 col-lg-3">
						          <a class="oss-link card-body"   

								          [routerLink]='"."' fragment="feasibility-studies"

						          >
								  <div class="card-header">
								     <h4 class="card-title">Feasibility Studies</h4>
								  </div>								          	
						           
							        <img class="card-img-top service-logo" src="../../assets/services/feasibility-icon-black-small.svg" alt="logo"/>
						            <p class="card-text">Often a requirement for government funding applications, our team can prepare detailed feasibility studies with schematic designs and cost estimates to help you get your project off the ground.</p>
						            <div class="card-footer">
						            	<a  
								          [routerLink]='"."' fragment="feasibility-studies"
						            	class="roboto-medium orangey btn btn-primary btn-lg btn-full"  role="button">Learn More</a>
						            </div>
						          </a>

						        </div>

								<div class="card col-xs-12 col-sm-6 col-md-3 col-lg-3">
						          <a class="oss-link card-body"   

								          [routerLink]='"."' fragment="building-code-review"

						          >
								  <div class="card-header">
								     <h4 class="card-title">Building Code Review</h4>
								  </div>								          	
						           
							        <img class="card-img-top service-logo" src="../../assets/services/building-code-icon-black-small.svg" alt="logo"/>
						            <p class="card-text">When purchasing a property or considering a renovation, allow us to educate you on the latest building code regulations to avoid building code infractions. Occupancy permits may also require a professional Building Code Review.</p>
						            <div class="card-footer">
						            	<a  
								          [routerLink]='"."' fragment="building-code-review"

						            	  class="roboto-medium orangey btn btn-primary btn-lg btn-full"  role="button">Learn More</a>
						            </div>
						          </a>

						        </div>

								<div class="card col-xs-12 col-sm-6 col-md-3 col-lg-3">
						          <a class="oss-link card-body"   

								          [routerLink]='"."' fragment="energy-code-review"								         
						          >
								  <div class="card-header">
								     <h4 class="card-title">Energy Code Review</h4>
								  </div>								          	
						           
							        <img class="card-img-top service-logo" src="../../assets/services/energy-code-icon-black-small.svg" alt="logo"/>
						            <p class="card-text">We have the expertise to educate you on the implications of the recently adopted Manitoba Energy Code for Buildings.</p>
						            <div class="card-footer">
						            	<a  

								          [routerLink]='"."' fragment="energy-code-review"								         

						            	class="roboto-medium orangey btn btn-primary btn-lg btn-full"  role="button">Learn More</a>
						            </div>
						          </a>

						        </div>								        
		        	
				        </div>
					</div>




					<div class="card-group col-xs-12 col-sm-12 col-md-6 col-lg-6 ">

					        <div class="oss-item-header">
						          <h6 class="black">Tools</h6>
					        </div>		

				        <div class="card-deck ">
							
								<div class="card col-xs-12 col-sm-6  ">
						          <a class="oss-link card-body"   
								          
								          [routerLink]='"."' fragment="tools"			         
						          >
								  <div class="card-header">
								    <h4 class="card-title">Computer 3D Modeling</h4>
								  </div>								          	
						            
							        <img class="card-img-top service-logo" src="../../assets/services/3d-model-icon-black-small.svg" alt="logo"/>
						            <p class="card-text">
						            Using software, we can help you see your project in virtual reality. Our 3D image renderings can be used as an effective promotional tool.
						            </p>

						          </a>
						            <div class="card-footer">
						            	<a  
								          [routerLink]='"."' fragment="tools"			         

						            	 class="roboto-medium orangey btn btn-primary btn-lg btn-full class-text"  role="button">Learn More</a>
						            </div>								          
						        </div>

								<div class="card col-xs-12 col-sm-6 ">
						          <a class="oss-link card-body"   

								          [routerLink]='"."' fragment="model-making"			         
						          

						          >
								  <div class="card-header">
								     <h4 class="card-title">Model Making</h4>
								  </div>								          	
						           
							        <img class="card-img-top service-logo" src="../../assets/services/model-icon-black-small.svg" alt="logo"/>
						            <p class="card-text">
							This physical form of modelling can be enjoyed by all without the need for software applications.									

						            </p>
						            <div class="card-footer">
						            	<a  
								          [routerLink]='"."' fragment="model-making"			         

						            	 class="roboto-medium orangey btn btn-primary btn-lg btn-full"  role="button">Learn More</a>
						            </div>
						          </a>

						        </div>
		        	
				        </div>
					</div>

		    </div>
		</div>
	</div>