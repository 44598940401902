
			<div  class="jumbotron entice_splash bk-white close-gap-bottom">
				<div class="container">


					<div class="row mobileMargin">
						<div ref="design" id="design" class="col-md-12 section-tagheader ">
						      <h2 class="roboto-medium black">Design<img class="hidden-xs" src="../../assets/tag-line.png" alt="arrow" /></h2>
						      <br><br><br><br>
						</div>	
					    <div class="col-md-12 black hidden-md hidden-lg spacer">

					    </div>				


					 	<div class=" row spacer vertical-alignment ">
				
						    <div class=" float-right col-xs-12 col-sm-12 col-md-4 col-lg-4  text-block centered">
						      	<div class="roboto black ">
							  	</div>
						    </div>


						    <div name="schematic-design" id="schematic-design" class="float-left col-xs-12 col-md-8 col-lg-8  text-block centered">
						      <div class="roboto black ">
								<div class="align-left detail-text ">
									<h6 class="h3 roboto-bold">Schematic Design</h6><br>
								</div>
							  </div>
						    </div>

						    <div class=" row  ">
						    </div>

						    <div class=" float-right col-xs-12 col-sm-12 col-md-4 col-lg-4  text-block centered">
						      <div class="roboto black ">
								<img class="service-icon hidden-xxs hidden-xs hidden-sm" src="../../assets/services/schematic-design-icon-black.svg" alt="" />


							  </div>
						    </div>


						    <div class="float-left col-xs-12 col-md-8 col-lg-8  text-block centered">
						      <div class="roboto black ">
								<div class="align-left detail-text ">
									<p class="roboto black">

In this initial phase we talk with the client and determine the project requirements and goals.
The architect develops rough study drawings that illustrate the basic concepts of the design. Illustrative sketches or computer renderings show spatial relationships, as well as scale and form. 
<br><br>
Research of jurisdictional regulations begins at this time as well as the development of initial rough cost estimates based on the total project size and complexity. 									</p>
							 
									<br>
							    	<a  
							    	
							    	routerLink="/Contact" 

							    	class="roboto-medium light-bluey btn btn-primary btn-lg" id="details-button" role="button">Get Started</a>
								
								</div>
							  </div>
						    </div>


						</div>

						<br><br>
						<hr>


					 	<div class=" row spacer vertical-alignment ">
				
						    <div class=" float-left col-xs-12 col-sm-12 col-md-4 col-lg-4  text-block centered">
						      	<div class="roboto black ">
							  	</div>
						    </div>


						    <div id="detailed-design" class="float-right col-xs-12 col-md-8 col-lg-8  text-block centered">
						      <div class="roboto black ">
								<div class="align-left detail-text ">
									<h6 class="h3 roboto-bold">Detailed Design</h6><br>
								</div>
							  </div>
						    </div>

						    <div class=" row  ">
						    </div>

						    <div class=" float-left col-xs-12 col-sm-12 col-md-4 col-lg-4  text-block centered">
						      <div class="roboto black ">
								<img class="service-icon hidden-xxs hidden-xs hidden-sm" src="../../assets/services/detailed-design-icon-black.svg" alt="" />
							  </div>
						    </div>


						    <div class="float-right col-xs-12 col-md-8 col-lg-8  text-block centered">
						      <div class="roboto black ">
								<div class="align-left detail-text ">
									<p class="roboto black">
Results from the schematic design phase are collected and lead to the finilizing of the design, specifying materials, window and door locations as well as general structural details. A detailed site plan, floor plan, elevations and section drawings are developed with full dimensions.<br><br>

Construction Documents are developed from the design development phase drawings. These drawings include specifications for contruction details and materials, and are often combined with structural, mechanical and electrical drawings, making them ideal for use during construction. The Construction Documents are sent to contractors for pricing or bidding as well as to the relevant bodies for required permit approvals.									
									</p>

									<br>
							    	<a routerLink="/Contact"  class="roboto-medium light-bluey btn btn-primary btn-lg" id="details-button" role="button">Get Started</a>
								
								</div>
							  </div>
						    </div>

						</div>

						<br><br>
						<hr>
<br><br>
<br><br>

					</div>



					<div class="row mobileMargin">
						<div id="administration" class="col-md-12 section-tagheader ">
						      <h2 class="roboto-medium black">Administration<img class="hidden-xs" src="../../assets/tag-line.png" alt="arrow" /></h2>
						      <br><br><br><br>
						</div>	
					    <div class="col-md-12 black hidden-md hidden-lg spacer">

					    </div>				




					 	<div class=" row spacer vertical-alignment ">
				
						    <div class=" float-right col-xs-12 col-sm-12 col-md-4 col-lg-4  text-block centered">
						      	<div class="roboto black ">
							  	</div>
						    </div>


						    <div id="tendering" class="float-left col-xs-12 col-md-8 col-lg-8  text-block centered">
						      <div class="roboto black ">
								<div class="align-left detail-text ">
									<h6 class="h3 roboto-bold">Tendering</h6><br>
								</div>
							  </div>
						    </div>

						    <div class=" row  ">
						    </div>

						    <div class=" float-right col-xs-12 col-sm-12 col-md-4 col-lg-4  text-block centered">
						      <div class="roboto black ">
								<img class="service-icon hidden-xxs hidden-xs hidden-sm" src="../../assets/services/tender-icon-black.svg" alt="" />
							  </div>
						    </div>


						    <div class="float-left col-xs-12 col-md-8 col-lg-8  text-block centered">
						      <div class="roboto black ">
								<div class="align-left detail-text ">
									<p class="roboto black">
We will advise you on the choice of contractor as well as recommend either an open or invited tender process depending on project needs. Using our vast experience in the tendering process, we will help evaluate bids, select contractors, and prepare the contracts necessary to go forward with the project.
									</p>
							 
									<br>
							    	<a  routerLink="/Contact"   class="roboto-medium light-bluey btn btn-primary btn-lg" id="details-button" role="button">Get Started</a>
								
								</div>
							  </div>
						    </div>


						</div>

						<br>
						<hr>


					 	<div class=" row spacer vertical-alignment ">
				
						    <div class=" float-left col-xs-12 col-sm-12 col-md-4 col-lg-4  text-block centered">
						      	<div class="roboto black ">
							  	</div>
						    </div>


						    <div id="contract-administration" class="float-right col-xs-12 col-md-8 col-lg-8  text-block centered">
						      <div class="roboto black ">
								<div class="align-left detail-text ">
									<h6 class="h3 roboto-bold">Contract Administration</h6><br>
								</div>
							  </div>
						    </div>

						    <div class=" row  ">
						    </div>

						    <div class=" float-left col-xs-12 col-sm-12 col-md-4 col-lg-4  text-block centered">
						      <div class="roboto black ">
								<img class="service-icon hidden-xxs hidden-xs hidden-sm" src="../../assets/services/contract-icon-black.svg" alt="" />
							  </div>
						    </div>


						    <div class="float-right col-xs-12 col-md-8 col-lg-8  text-block centered">
						      <div class="roboto black ">
								<div class="align-left detail-text ">
									<p class="roboto black">
We will consult with you and provide timely advice during all parts of construction. On-site visits will determine if construction is proceeding in accordance with the contract, and that the design, materials, and workmanship meet acceptable standards. Regular progress reports will keep you informed, and stage payments will be authorized for the contractor. We will be there with you until the project is completed.
									</p>

									<br>
							    	<a  routerLink="/Contact"   class="roboto-medium light-bluey btn btn-primary btn-lg" id="details-button" role="button">Get Started</a>
								
								</div>
							  </div>
						    </div>

						</div>

						<br>
						<hr>


<div class=" row spacer vertical-alignment ">
				
						    <div class=" float-right col-xs-12 col-sm-12 col-md-4 col-lg-4  text-block centered">
						      	<div class="roboto black ">
							  	</div>
						    </div>


						    <div id="post-occupancy" class="float-left col-xs-12 col-md-8 col-lg-8  text-block centered">
						      <div class="roboto black ">
								<div class="align-left detail-text ">
									<h6 class="h3 roboto-bold">Post Occupany</h6><br>
								</div>
							  </div>
						    </div>

						    <div class=" row  ">
						    </div>

						    <div class=" float-right col-xs-12 col-sm-12 col-md-4 col-lg-4  text-block centered">
						      <div class="roboto black ">
								<img class="service-icon hidden-xxs hidden-xs hidden-sm" src="../../assets/services/post-occupancy-icon-black.svg" alt="" />
							  </div>
						    </div>


						    <div class="float-left col-xs-12 col-md-8 col-lg-8  text-block centered">
						      <div class="roboto black ">
								<div class="align-left detail-text ">
									<p class="roboto black">
After project completion we can still be useful. A post occupancy evaluation is recommended to review project delivery to determine if the building is operating as planned. This should ideally be done within the building's warranty period.
<br><br>
The contractor will be invited to attend to any and all defects identified at practical completion and after the post occupancy evaluation.									</p>
							 
									<br>
							    	<a  routerLink="/Contact"   class="roboto-medium light-bluey btn btn-primary btn-lg" id="details-button" role="button">Get Started</a>
								
								</div>
							  </div>
						    </div>


						</div>

						<br><br>
						<hr>
<br><br>
<br><br>

					</div>

					<div class="row mobileMargin">
						<div id="planning" class="col-md-12 section-tagheader ">
						      <h2 class="roboto-medium black">Planning and Studies<img class="hidden-xs" src="../../assets/tag-line.png" alt="arrow" /></h2>
						      <br><br><br><br>
						</div>	
					    <div class="col-md-12 black hidden-md hidden-lg spacer">

					    </div>				




					 	<div class=" row spacer vertical-alignment ">
				
						    <div class=" float-right col-xs-12 col-sm-12 col-md-4 col-lg-4  text-block centered">
						      	<div class="roboto black ">
							  	</div>
						    </div>


						    <div id="development-spatial-planning" class="float-left col-xs-12 col-md-8 col-lg-8  text-block centered">
						      <div class="roboto black ">
								<div class="align-left detail-text ">
									<h6 class="h3 roboto-bold">Development & Spatial Planning</h6><br>
								</div>
							  </div>
						    </div>

						    <div class=" row  ">
						    </div>

						    <div class=" float-right col-xs-12 col-sm-12 col-md-4 col-lg-4  text-block centered">
						      <div class="roboto black ">
								<img class="service-icon hidden-xxs hidden-xs hidden-sm" src="../../assets/services/spatial-icon-black.svg" alt="" />
							  </div>
						    </div>


						    <div class="float-left col-xs-12 col-md-8 col-lg-8  text-block centered">
						      <div class="roboto black ">
								<div class="align-left detail-text ">
									<p class="roboto black">
We offer comprehensive planning, approvals and development management to communities, landowners and non-governmental oganizations. We provide analysis and advice on the options and strategies in the successful delivery of small to large scale projects and reference comparable examples and good practices. We also take community and business players through the process.
<br><br>
Working from concept through to construction allows us to guide our clients at each stage of a project, anticipating the challenges that come with complex development.									</p>
							 
									<br>
							    	<a  routerLink="/Contact"  class="roboto-medium light-bluey btn btn-primary btn-lg" id="details-button" role="button">Get Started</a>
								
								</div>
							  </div>
						    </div>


						</div>

						<br>
						<hr>


					 	<div class=" row spacer vertical-alignment ">
				
						    <div class=" float-left col-xs-12 col-sm-12 col-md-4 col-lg-4  text-block centered">
						      	<div class="roboto black ">
							  	</div>
						    </div>


						    <div id="feasibility-studies" class="float-right col-xs-12 col-md-8 col-lg-8  text-block centered">
						      <div class="roboto black ">
								<div class="align-left detail-text ">
									<h6 class="h3 roboto-bold">Feasibility Studies</h6><br>
								</div>
							  </div>
						    </div>

						    <div class=" row  ">
						    </div>

						    <div class=" float-left col-xs-12 col-sm-12 col-md-4 col-lg-4  text-block centered">
						      <div class="roboto black ">
								<img class="service-icon hidden-xxs hidden-xs hidden-sm" src="../../assets/services/feasibility-icon-black.svg" alt="" />
							  </div>
						    </div>


						    <div class="float-right col-xs-12 col-md-8 col-lg-8  text-block centered">
						      <div class="roboto black ">
								<div class="align-left detail-text ">
									<p class="roboto black">
Feasibility studies work to establish the physical and economic framework for future projects and create a clear vision inspired by the client's desires and objectives.
<br><br>
These studies include many services such as research and analysis of existing conditions, site selection, building audits, program development, needs assessment, community consultations, economic feasibility, business and operational planning, environmental analysis and concept design. Our many years of experience providing pre-design services, help us understand the unique conditions, goals, programmatic uses and financial constraints of any proposed project.
									</p>

									<br>
							    	<a  routerLink="/Contact"  class="roboto-medium light-bluey btn btn-primary btn-lg" id="details-button" role="button">Get Started</a>&nbsp;&nbsp;
							    	<a  
							          [routerLink]='"."' fragment="feasibility-study-list"			         
							    	


							    	class="roboto-medium light-orangey btn btn-primary btn-lg" id="details-button" role="button">View Studies</a>
								
								</div>
							  </div>
						    </div>

						</div>

						<br>
						<hr>


<div class=" row spacer vertical-alignment ">
				
						    <div class=" float-right col-xs-12 col-sm-12 col-md-4 col-lg-4  text-block centered">
						      	<div class="roboto black ">
							  	</div>
						    </div>


						    <div id="building-code-review" class="float-left col-xs-12 col-md-8 col-lg-8  text-block centered">
						      <div class="roboto black ">
								<div class="align-left detail-text ">
									<h6 class="h3 roboto-bold">Building Code Review</h6><br>
								</div>
							  </div>
						    </div>

						    <div class=" row  ">
						    </div>

						    <div class=" float-right col-xs-12 col-sm-12 col-md-4 col-lg-4  text-block centered">
						      <div class="roboto black ">
								<img class="service-icon hidden-xxs hidden-xs hidden-sm" src="../../assets/services/building-code-icon-black.svg" alt="" />
							  </div>
						    </div>


						    <div class="float-left col-xs-12 col-md-8 col-lg-8  text-block centered">
						      <div class="roboto black ">
								<div class="align-left detail-text ">
									<p class="roboto black">
A building design code review is essential in forestalling any nasty surprises at the final stages of developing construction documents for agency review, bidding and construction. Full understanding of the national building code of Canada, provincial codes, and other building and fire codes across North America - both the 'letter' and the 'intent' - is necessary for the proposed project to meet the minimum standards required by the governing authorities.
<br><br>
We're committed to safety and property protection through the application of standards and criteria that reflect current knowledge and technology.
</p>
							 
									<br>
							    	<a  routerLink="/Contact"  class="roboto-medium light-bluey btn btn-primary btn-lg" id="details-button" role="button">Get Started</a>
								
								</div>
							  </div>
						    </div>


						</div>

						<br><br>
						<hr>
<div class=" row spacer vertical-alignment ">
				
						    <div class=" float-left col-xs-12 col-sm-12 col-md-4 col-lg-4  text-block centered">
						      	<div class="roboto black ">
							  	</div>
						    </div>


						    <div id="energy-code-review" class="float-right col-xs-12 col-md-8 col-lg-8  text-block centered">
						      <div class="roboto black ">
								<div class="align-left detail-text ">
									<h6 class="h3 roboto-bold">Energy Code Review</h6><br>
								</div>
							  </div>
						    </div>

						    <div class=" row  ">
						    </div>

						    <div class=" float-left col-xs-12 col-sm-12 col-md-4 col-lg-4  text-block centered">
						      <div class="roboto black ">
								<img class="service-icon hidden-xxs hidden-xs hidden-sm" src="../../assets/services/energy-code-icon-black.svg" alt="" />
							  </div>
						    </div>


						    <div class="float-right col-xs-12 col-md-8 col-lg-8  text-block centered">
						      <div class="roboto black ">
								<div class="align-left detail-text ">
									<p class="roboto black">
We offer energy code review and compliance coordination for any project and will take you through the implications of the new Manitoba energy code for buildings.
<br><br>
Reviews include envelope examination of plans and energy calculations for residential occupancies such as single-family structures as well as multi-family and commercial occupancies. Scope includes prescriptive approaches, component performance, as well as justifying compliance using total building performance. Energy code reviews include lighting system controls.	
									</p>

									<br>
							    	<a  routerLink="/Contact"  class="roboto-medium light-bluey btn btn-primary btn-lg" id="details-button" role="button">Get Started</a>
								
								</div>
							  </div>
						    </div>

						</div>

						<br>
						<hr>



					</div>






				<div class="row mobileMargin">
						<div id="tools" class="col-md-12 section-tagheader ">
						      <h2 class="roboto-medium black">Tools<img class="hidden-xs" src="../../assets/tag-line.png" alt="arrow" /></h2>
						      <br><br><br><br>
						</div>	
					    <div class="col-md-12 black hidden-md hidden-lg spacer">

					    </div>				




					 	<div class=" row spacer vertical-alignment ">
				
						    <div class=" float-right col-xs-12 col-sm-12 col-md-4 col-lg-4  text-block centered">
						      	<div class="roboto black ">
							  	</div>
						    </div>


						    <div id="computer-3d-modeling" class="float-left col-xs-12 col-md-8 col-lg-8  text-block centered">
						      <div class="roboto black ">
								<div class="align-left detail-text ">
									<h6 class="h3 roboto-bold">Computer 3D Modeling</h6><br>
								</div>
							  </div>
						    </div>

						    <div class=" row  ">
						    </div>

						    <div class=" float-right col-xs-12 col-sm-12 col-md-4 col-lg-4  text-block centered">
						      <div class="roboto black ">
								<img class="service-icon hidden-xxs hidden-xs hidden-sm" src="../../assets/services/3d-model-icon-black.svg" alt="" />
							  </div>
						    </div>


						    <div class="float-left col-xs-12 col-md-8 col-lg-8  text-block centered">
						      <div class="roboto black ">
								<div class="align-left detail-text ">
									<p class="roboto black">

3D Modeling can be an important step in visualizing your ideas in a correctly rendered image. 3D models can be changed at any time during the design process allowing detailed and exploded views, sections, elevations and greatly enhanced presentations.
<br><br>

We offer artistic architectural rendering, creative 3D illustration, photo-realistic graphics, presentations or animations of your CAD plans and computer generated designs. 
These will help you visualize people actually using your spaces and provide a solid presentation platform that best represents your design vision.
						</p>
							 
									<br>
							    	<a  routerLink="/Contact"  class="roboto-medium light-bluey btn btn-primary btn-lg" id="details-button" role="button">Get Started</a>
								
								</div>
							  </div>
						    </div>


						</div>

						<br><br>
						<hr>


					 	<div class=" row spacer vertical-alignment ">
				
						    <div class=" float-left col-xs-12 col-sm-12 col-md-4 col-lg-4  text-block centered">
						      	<div class="roboto black ">
							  	</div>
						    </div>


						    <div id="model-making" class="float-right col-xs-12 col-md-8 col-lg-8  text-block centered">
						      <div class="roboto black ">
								<div class="align-left detail-text ">
									<h6 class="h3 roboto-bold">Model Making</h6><br>
								</div>
							  </div>
						    </div>

						    <div class=" row  ">
						    </div>

						    <div class=" float-left col-xs-12 col-sm-12 col-md-4 col-lg-4  text-block centered">
						      <div class="roboto black ">
								<img class="service-icon hidden-xxs hidden-xs hidden-sm" src="../../assets/services/model-icon-black.svg" alt="" />
							  </div>
						    </div>


						    <div class="float-right col-xs-12 col-md-8 col-lg-8  text-block centered">
						      <div class="roboto black ">
								<div class="align-left detail-text ">
									<p class="roboto black">

We make models by hand or through 3D Printing technologies. 3D printing is an additive manufacturing process for making a three dimensional solid object from a digital model. It's an exceptional tool for concept models, prototypes and accurately modeling complex geometries and shapes.				
									</p>

									<br>
							    	<a  routerLink="/Contact"  class="roboto-medium light-bluey btn btn-primary btn-lg" id="details-button" role="button">Get Started</a>
								
								</div>
							  </div>
						    </div>

						</div>

						<br><br>
						<hr>
<br><br>
<br><br>

					</div>















					<div class="row mobileMargin">
						<div id="feasibility-study-list" class="col-lg-12 col-md-12 section-tagheader ">

						      <br><br><br><br>
						</div>	
					    <div class="col-md-12  black hidden-md hidden-lg spacer">

					    </div>				




					 	<div class="row spacer vertical-alignment ">
				





						    <div id="computer-3d-modeling" class="float-left col-xs-12 col-md-12 col-lg-12 text-block centered">
						      <div class="roboto black ">
								<div class="align-left detail-text ">
									<h6 class="h3 roboto-bold">Successful Feasibility Studies</h6><br>
								</div>
							  </div>
						    </div>

						    <div class=" row  ">
						    </div>



						    <div class="float-left col-xs-12 col-md-12 col-lg-12  text-block centered">
						      <div class="roboto black ">
								<div class="align-left detail-text ">
									<p class="roboto black">

			We've had a long string of successful feasibility studies under our belt. Here is a comprehensive list:
<br><br>

						</p>
							 
									<br>
					














<div class="blue-date-container col-lg-8 col-md-8 col-sm-12 col-xs-12">

								

								<div class="col-lg-10 col-md-10 col-sm-11 col-xs-10 float-right "> 
									<div class=" align-left roboto  location-text">
									<img class="location-icon" src="../../assets/services/loc.svg" alt="" />&nbsp;

									
									Kitchenuhmaykoosib, ON

									</div>
									<div class=" align-left roboto black emphasized">

									Business Center,  Kitchenuhmaykoosib Inninuwug (First Nation)  <br> <div class="completion">Funding Phase</div>

									</div>
								</div>
								<div class="float-left blue-date-box ">
								
								2018

								<br>

								JAN

								</div>


						</div>	<br><br><br><br><br><br>










<div class="blue-date-container col-lg-8 col-md-8 col-sm-12 col-xs-12">

								

								<div class="col-lg-10 col-md-10 col-sm-11 col-xs-10 float-right "> 
									<div class=" align-left roboto  location-text">
									<img class="location-icon" src="../../assets/services/loc.svg" alt="" />&nbsp;

									
									Pikangikum, ON

									</div>
									<div class=" align-left roboto black emphasized">

									Offices and Training Center, Pikangikum Education Authority  <br> <div class="completion">Funding Phase</div>

									</div>
								</div>
								<div class="float-left blue-date-box ">
								
								2017

								<br>

								DEC

								</div>


						</div>	<br><br><br><br><br><br>


















<div class="blue-date-container col-lg-8 col-md-8 col-sm-12 col-xs-12">

								

								<div class="col-lg-10 col-md-10 col-sm-11 col-xs-10 float-right "> 
									<div class=" align-left roboto  location-text">
									<img class="location-icon" src="../../assets/services/loc.svg" alt="" />&nbsp;

									
									Sachigo Lake, ON

									</div>
									<div class=" align-left roboto black emphasized">

									Community Multi-purpose Center, Sachigo Lake First Nation<br> <div class="completion">Funding Phase</div>

									</div>
								</div>
								<div class="float-left blue-date-box ">
								
								2014

								<br>

								MAY

								</div>


						</div>	<br><br><br><br><br><br>





















<div class="blue-date-container col-lg-8 col-md-8 col-sm-12 col-xs-12">

								

								<div class="col-lg-10 col-md-10 col-sm-11 col-xs-10 float-right "> 
									<div class=" align-left roboto  location-text">
									<img class="location-icon" src="../../assets/services/loc.svg" alt="" />&nbsp;

									
									Keewaywin, ON

									</div>
									<div class=" align-left roboto black emphasized">

									Multi-purpose Facility, Keewaywin First Nation <br> <div class="completion">Funding Phase</div>

									</div>
								</div>
								<div class="float-left blue-date-box ">
								
								2013

								<br>

								SEP

								</div>


						</div>	<br><br><br><br><br><br>












	<div class="blue-date-container col-lg-8 col-md-8 col-sm-12 col-xs-12">

								

								<div class="col-lg-10 col-md-10 col-sm-11 col-xs-10 float-right "> 
									<div class=" align-left roboto  location-text">
									<img class="location-icon" src="../../assets/services/loc.svg" alt="" />&nbsp;

									
									Bearskin Lake, ON

									</div>
									<div class=" align-left roboto black emphasized">

									Community Center Addition, Bearskin Lake First Nation <br> <div class="completion">Built 2017</div>

									</div>
								</div>
								<div class="float-left blue-date-box ">
								
								2013

								<br>

								MAR

								</div>


						</div>	<br><br><br><br><br><br>














						<div class="blue-date-container col-lg-8 col-md-8 col-sm-12 col-xs-12">

								

								<div class="col-lg-10 col-md-10 col-sm-11 col-xs-10 float-right "> 
									<div class=" align-left roboto  location-text">
									<img class="location-icon" src="../../assets/services/loc.svg" alt="" />&nbsp;

									
									Pikangikum, ON

									</div>
									<div class=" align-left roboto black emphasized">

									Business Center, Pikangikum First Nation <br> <div class="completion">Funding Phase</div>

									</div>
								</div>
								<div class="float-left blue-date-box ">
								
								2012

								<br>

								NOV

								</div>


						</div>	<br><br><br><br><br><br>












						<div class="blue-date-container col-lg-8 col-md-8 col-sm-12 col-xs-12">

								

								<div class="col-lg-10 col-md-10 col-sm-11 col-xs-10 float-right "> 
									<div class=" align-left roboto  location-text">
									<img class="location-icon" src="../../assets/services/loc.svg" alt="" />&nbsp;

									
									Lac Seul, ON

									</div>
									<div class=" align-left roboto black emphasized">

									Cultural Center, Lac Seul First Nation  <br> <div class="completion">Funding Phase</div>

									</div>
								</div>
								<div class="float-left blue-date-box ">
								
								2012

								<br>

								JUN

								</div>


						</div>	<br><br><br><br><br><br>












						<div class="blue-date-container col-lg-8 col-md-8 col-sm-12 col-xs-12">

								

								<div class="col-lg-10 col-md-10 col-sm-11 col-xs-10 float-right "> 
									<div class=" align-left roboto  location-text">
									<img class="location-icon" src="../../assets/services/loc.svg" alt="" />&nbsp;

									
									Slate Falls, ON

									</div>
									<div class=" align-left roboto black emphasized">

									Community, Sport and Recreation Center, Slate Falls First Nation <br> <div class="completion">Built 2016</div>

									</div>
								</div>
								<div class="float-left blue-date-box ">
								
								2009

								<br>

								MAR

								</div>


						</div>	<br><br><br><br><br><br>







						<div class="blue-date-container col-lg-8 col-md-8 col-sm-12 col-xs-12">

								

								<div class="col-lg-10 col-md-10 col-sm-11 col-xs-10 float-right "> 
									<div class=" align-left roboto  location-text">
									<img class="location-icon" src="../../assets/services/loc.svg" alt="" />&nbsp;

									
									Wabaseemoong, ON

									</div>
									<div class=" align-left roboto black emphasized">

									Multi-purpose Facility and Business Center, Wabaseemoong Independent Nations <br> <div class="completion">Built 2012</div>

									</div>
								</div>
								<div class="float-left blue-date-box ">
								
								2009

								<br>

								JAN

								</div>


						</div>	<br><br><br><br><br><br>






















		


				





					




					


				





						<div class="blue-date-container col-lg-8 col-md-8 col-sm-12 col-xs-12">

								

								<div class="col-lg-10 col-md-10 col-sm-11 col-xs-10 float-right "> 
									<div class=" align-left roboto  location-text">
									<img class="location-icon" src="../../assets/services/loc.svg" alt="" />&nbsp;

									
									North Caribou Lake, ON

									</div>
									<div class=" align-left roboto black emphasized">

									Community, Business, Sport, and Recreation Center, North Caribou Lake First Nation <br> <div class="completion">Built 2013</div>

									</div>
								</div>
								<div class="float-left blue-date-box ">
								
								2008

								<br>

								AUG

								</div>


						</div>	<br><br><br><br><br><br>











		<div class="blue-date-container col-lg-8 col-md-8 col-sm-12 col-xs-12">

								

								<div class="col-lg-10 col-md-10 col-sm-11 col-xs-10 float-right "> 
									<div class=" align-left roboto  location-text">
									<img class="location-icon" src="../../assets/services/loc.svg" alt="" />&nbsp;

									
									Pikangikum, ON

									</div>
									<div class=" align-left roboto black emphasized">

									Community, Early Childhood and Family Center, Pikangikum First Nation <br> <div class="completion">Built 2008</div>

									</div>
								</div>
								<div class="float-left blue-date-box ">
								
								2006

								<br>

								DEC

								</div>


						</div>	<br><br><br><br><br><br>


































	<div class="blue-date-container col-lg-8 col-md-8 col-sm-12 col-xs-12">

								

								<div class="col-lg-10 col-md-10 col-sm-11 col-xs-10 float-right "> 
									<div class=" align-left roboto  location-text">
									<img class="location-icon" src="../../assets/services/loc.svg" alt="" />&nbsp;

									
									Muskrat Dam, ON

									</div>
									<div class=" align-left roboto black emphasized">

									Community/Business Center, Muskrat Dam Lake First Nation <br> <div class="completion">Built 2008</div>

									</div>
								</div>
								<div class="float-left blue-date-box ">
								
								2006

								<br>

								DEC

								</div>


						</div>	<br><br><br><br><br><br>












	<div class="blue-date-container col-lg-8 col-md-8 col-sm-12 col-xs-12">

								

								<div class="col-lg-10 col-md-10 col-sm-11 col-xs-10 float-right ">  
									<div class=" align-left roboto  location-text">
									<img class="location-icon" src="../../assets/services/loc.svg" alt="" />&nbsp;

									
									Bearskin Lake, ON

									</div>
									<div class=" align-left roboto black emphasized">

									Small Business Center, Bearskin Lake First Nation <br> <div class="completion">Built 2006</div>

									</div>
								</div>
								<div class="float-left blue-date-box ">
								
								2005

								<br>

								DEC

								</div>


						</div><br><br><br><br><br><br>














		<div class="blue-date-container col-lg-8 col-md-8 col-sm-12 col-xs-12">

								

								<div class="col-lg-10 col-md-10 col-sm-11 col-xs-10 float-right "> 
									<div class=" align-left roboto  location-text">
									<img class="location-icon" src="../../assets/services/loc.svg" alt="" />&nbsp;

									
									Bearskin Lake, ON

									</div>
									<div class=" align-left roboto black emphasized">

									Community Center, Bearskin Lake First Nation <br> <div class="completion">Built 2005</div>

									</div>
								</div>
								<div class="float-left blue-date-box ">
								
								2004

								<br>

								MAY

								</div>


						</div>	<br><br><br><br><br><br>






<p class="roboto black">

			Let us make your project a reality. &nbsp; &nbsp; 
						<a  routerLink="/Contact"  class="roboto-medium light-bluey btn btn-primary btn-lg" id="details-button" role="button">Get Started</a>

<br><br>

						</p>



								
								</div>

							  </div>

						    </div>
						</div>

	

						<br><br>
						<hr>

					</div>
















					<div  class="jumbotron return_splash bk-white close-gap-bottom ">
						<div class="container  return_up">
							<div class="row ">
								<div class="col-md-12 section-tagheader">
								      <a 
							          [routerLink]='"."' fragment="services-splash"			         
								      


								      ><h2 class="roboto-medium black  text-right"><img class="" src="../../assets/arrow_left_up.png" alt="arrow" /> <em class="return-top noitalics">Return</em></h2></a>
								</div>	
							    <div class="col-md-12 white hidden-md hidden-lg spacer"></div>					
							    <div class="col-md-12 white hidden-md hidden-lg spacer"></div>					
							    <div class="col-md-12 white hidden-md hidden-lg spacer"></div>					

							</div>
						</div>
					</div>
				</div>
			</div>