
   <footer class="footer bk-black   ">
	   	<div class="container">
	      <div class=" row ">
	        <img class="logo_black" src="../assets/VA_logo_sm.png" alt="logo black"/>

				<div class="col-sm-12 col-md-12 bottom-border">
					
				</div>

	      </div>
	      <div class="row">


			  <div class="align-right float-right foot-col col-md-2 col-sm-3 col-xs-4">
			  		<h6 class=""><em class="times" >VOITEC</em> social</h6>
			  		<a class="social-logo" href="https://www.facebook.com/voitec-architect">
			  			<!-- <i class="fa fa-facebook-square fa-lg white"></i> -->
			  			<img src="/assets/socialicons/facebook.png" alt="">

			  		</a>


			  		<a class="social-logo" href="https://www.instagram.com">
			  			<img src="/assets/socialicons/instagram.png" alt="">
			  		</a>
			  		
			  		<a class="social-logo" href="https://twitter.com/voitec_arch">
			  			<!-- <i class="fa fa-twitter-square fa-lg white"></i> -->
			  			<img src="/assets/socialicons/twitter.png" alt="">

			  		</a>
			  		
			  		<a class="social-logo" href="https://www.linkedin.com">
			  			<!-- <i class="fab fa-linkedin-in"></i> -->
			  			<img src="/assets/socialicons/linkedin.png" alt="">

			  		</a>

			  </div>
	      </div>
		</div>
    </footer>
