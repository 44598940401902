import { Component, OnInit } from '@angular/core';
import { Router, NavigationEnd, ActivatedRoute } from '@angular/router';
import { Location } from '@angular/common';

@Component({
  selector: 'app-return-to-projects',
  templateUrl: './return-to-projects.component.html',
  styleUrls: ['./return-to-projects.component.css']
})
export class ReturnToProjectsComponent implements OnInit {

  private fragment: string;

  constructor(private router: Router, private route:ActivatedRoute) { }

  ngOnInit(): void {
  	this.route.fragment.subscribe(fragment => {this.fragment = fragment; })
  }

  ngAfterViewInit():void {
  	try {
  		document.querySelector('#' + this.fragment).scrollIntoView();
  	} catch (e){}
  }
  navigateBack(){
  	this.router.navigate(['/Projects']);
  }
  // navigateBack(id:number){
  	//navigate to project/id page
  	// this.router.navigate(['Project', id]);
  // }
}
