import { Component, OnInit } from '@angular/core';
import { Router, NavigationEnd, ActivatedRoute } from '@angular/router';
import { Location } from '@angular/common';

@Component({
  selector: 'app-return-top',
  templateUrl: './return-top.component.html',
  styleUrls: ['./return-top.component.css']
})

export class ReturnTopComponent implements OnInit {

  private fragment: string;


  constructor(private route:ActivatedRoute) {}

  ngOnInit(): void {
  	this.route.fragment.subscribe(fragment => {this.fragment = fragment; })
  }

  ngAfterViewInit():void {
  	try {
  		document.querySelector('#' + this.fragment).scrollIntoView();
  	} catch (e){}
  }

  clicker(){
  	console.log("clicked");
  }

}
