<div class="jumbotron entice_splash bk-white close-gap-bottom">
    <div class="container">
        <div class="row">
            <div class="col-md-12 section-tagheader ">
                <h2 class="roboto-medium black">Position: Project Architect<img class="" src="../../assets/tag-line.png"
                        alt="arrow" />
                </h2><br><br>
            </div>
            <!-- <div class="col-md-12 black hidden-md hidden-lg spacer">

            </div> -->

            <div class="row spacer">
                <div class="col-md-5 white">

                </div>
                <div class="col-md-6 text-block">
                    <p class="roboto black ">

                        Voitec Architect is looking to hire a motivated Project Designer/Architect with experience in
                        housing, residential or commercial projects. Applicants should have strong organizational and
                        project management skills to plan & coordinate all phases of architectural practice from
                        feasibility to construction administration.
                        <br>


                    </p>
                </div>
            </div>
            <div class="row spacer">
                <div class="col-md-5 white">
                </div>
                <div class="col-md-6 text-block">
                    <p class="roboto black ">
                        <b>Responsibilities: </b>
                        <br>
                        <br>
                        &nbsp;• Be capable of delivering a fully coordinated construction document set<br>
                        <br>
                        &nbsp;• Coordinate with consultants, contractors, fabricators, and agencies to meet overall
                        project
                        objectives<br>
                        <br>
                        &nbsp;• Keen understanding of local codes and ability to ensure compliance<br>
                        <br>
                        &nbsp;• Conduct quality control review of documents<br>
                        <br>
                        &nbsp;• Manage client expectations and oversee all project related communication<br>
                        <br>
                        &nbsp;• Motivated; good written, verbal, and graphic capabilities; collaborative, organized, and
                        detail-oriented<br>

                        <!-- <br> -->
                    </p>
                </div>
            </div>

            <!-- ++++++++++++++++++++++++++ -->
            <div class="row spacer">
                <div class="col-md-5 white">
                </div>
                <div class="col-md-6 text-block">
                    <p class="roboto black ">
                        <b>Type: </b>
                        Full-Time
                        <br>
                    </p>
                </div>
            </div>
            <!-- ******************************** -->
            <div class="row spacer">
                <div class="col-md-5 white">
                </div>
                <div class="col-md-6 text-block">
                    <p class="roboto black ">
                        <b>Experience: </b>
                        2+ years of work experience in residential, commercial, multifamily/mixed-use projects
                        <br>
                    </p>
                </div>
            </div>
            <!-- ******************************** -->
            <div class="row spacer">
                <div class="col-md-5 white">
                </div>
                <div class="col-md-6 text-block">
                    <p class="roboto black ">
                        <b>Education: </b>
                        B.Arch, License Not Required, Familiarity with Manitoba Building Code

                        <br>
                    </p>
                </div>
            </div>
            <!-- ******************************** -->
            <div class="row spacer">
                <div class="col-md-5 white">
                </div>
                <div class="col-md-6 text-block">
                    <p class="roboto black ">
                        <b>Software: </b>
                        Autodesk AutoCAD, with knowledge of AutoCAD Architecture greatly preferred, SketchUp, Microsoft
                        Office software and familiarity with Adobe Creative Suite (Photoshop, InDesign, Illustrator)
                        <br>
                    </p>
                </div>
            </div>
            <!-- ******************************** -->
            <div class="row spacer">
                <div class="col-md-5 white">
                </div>
                <div class="col-md-6 text-block">
                    <p class="roboto black ">
                        <b>Salary: </b>
                        Dependant on skills/experience.
                        We offer medical and Dental, Paid Vacation, sick time, holidays.
                        <br>
                    </p>
                </div>
            </div>
            <!-- ******************************** -->

            <div class="row spacer">
                <div class="col-md-5 white">
                </div>
                <div class="col-md-6 text-block">
                    <p class="roboto black ">
                        Submit Cover Letter, Resume, & Work Samples in PDF format via email to:
                        <br>

                        <span>
                            <img class="email_png" src="../../assets/office_email_black.png" alt="" />
                        </span>

                    </p>
                </div>

            </div>
        </div>

    </div>
</div>