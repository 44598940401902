
			<div  class="jumbotron entice_splash bk-black close-gap-bottom  noradius">
				<div class="container">
					<div class="row">
						<div class="col-md-12 section-tagheader">
						      <h2 class="roboto-medium white">Workflow<img class="hidden-xs" src="../../assets/tag-line-white.png" alt="arrow" /></h2>
						</div>	
					    <div class="col-md-12 white hidden-md hidden-lg spacer"></div>					
					    <div class="col-md-12 white hidden-md hidden-lg spacer"></div>					
					    <div class="col-md-12 white hidden-md hidden-lg spacer"></div>					

				 	<div class="row spacer ">
					    <div class="col-md-12 white  spacer ">
					    	<img class="wide-img" src="../../assets/voitec-workflow.png" alt="workflow" />
					    </div>	
						<div class="col-md-12 white spacer ">
						  <div class="col-md-4 ">
						    <div class="text-center workflow-text ">
						      <h6 class="h3 roboto-bold">Design Phase</h6>
						      <p>Creating common vision </p>
						    </div>
						  </div>
						  <div class="col-md-4">
						    <div class="text-center workflow-text">
						      <h6 class="h3 roboto-bold">Implementation</h6>
						      <p>Permits, Tendering and Contracts</p>
						    </div>
						  </div>
						  <div class="col-md-4">
						    <div class="text-center workflow-text">
						      <h6 class="h3 roboto-bold">Post Occupancy</h6>
						      <p>Scheduling etc during warranty period.</p>
						    </div>
						  </div>
						</div>


					</div>

				    </div>
				</div>
			</div>