<!-- Individual Project layout -->
<div  class="jumbotron projects_showcase bk-white close-gap-bottom over-z top-shadow-black">
	<div class="container ">
			<!-- 	<app-return-to-projects></app-return-to-projects> -->

	  	<div class="container spacer bk-white" >
	  		<div class="row">
    			<div class="  col-xs-12 col-sm-12 col-md-12 col-lg-12  squish-animation">

					<!-- carousel  -->
					<div class="carousel slide" data-ride="carousel" id={{current_project.project_number}}>

						<div class="carousel-inner">

							<!-- carousel item 1 -->
							<div class="carousel-item spacer">
								<img class="main-slide" 	[@animationState]="state"
															src="{{getSrc(current_project.project_number, 2)}}" 
															srcset="{{getSrcSet(current_project.project_number, 2)}}" 
															sizes="(min-width: 1200px) 1200px, 100vw"
															alt="1 Photo"/>

								<div class="carousel-caption  align-left">
									<h4 class="carousel-title black paddless noselect">{{current_project.title}}</h4>
		

									<table class=" carousel-text noselect">
										<tbody>
											<tr class="table-tr" >
												<th class="table-desc"> <em class="desc-em">address: </em></th>
												<th class="table-info">{{current_project.address}}</th>
											</tr>
											<tr class="table-tr" >
												<th class="table-desc"> <em class="desc-em"> </em></th>
												<th class="table-info">{{current_project.location}}</th>
											</tr>
											<tr class="table-tr" >
												<th  class="table-desc"><em class="desc-em">budget: </em></th>
												<th class="table-info">$ {{current_project.budget}}</th>
											</tr>
											<tr class="table-tr" >
												<th  class="table-desc"><em class="desc-em">Area: </em></th>
												<th class="table-info">{{current_project.area}} sq.ft.</th>
											</tr>
											<tr class="table-tr" >
												<th  class="table-desc"><em class="desc-em">Lot: </em></th>
												<th class="table-info">{{current_project.lot_area}} sq.ft.</th>
											</tr>											
											<tr class="table-tr" >
												<th  class="table-desc"><em class="desc-em">unit: </em></th>
												<th class="table-info">{{current_project.unit_area}} sq.ft.</th>
											</tr>
										</tbody>
									</table>
									
									<hr class="hidden-xs paddless"/>
									<div class="carousel-text paddless hidden-xs noselect"><em class="desc-desc"> {{current_project.description}}</em></div>								

							    </div>
							</div>
							<!-- carousel item 2 -->
							<div class="carousel-item spacer">
								<img class="main-slide" 	[@animationState]="state"
															src="{{getSrc(current_project.project_number, 1)}}" 
															srcset="{{getSrcSet(current_project.project_number, 1)}}" 
															sizes="(min-width: 1200px) 1200px, 100vw"
															alt="2 Photo"/>
								<div class="carousel-caption  align-left">
								<!-- <h4 class="carousel-title white paddless noselect bk-transparent-black">{{current_project.title}}: {{current_project.year}}</h4> -->
							    </div>
							</div>
							<!-- carousel item 3 -->
							<div class="carousel-item spacer">
								<img class="main-slide" 	[@animationState]="state"
															src="{{getSrc(current_project.project_number, 3)}}" 
															srcset="{{getSrcSet(current_project.project_number, 3)}}" 
															sizes="(min-width: 1200px) 1200px, 100vw"
															alt="3 Photo"/>
								<div class="carousel-caption  align-left">
								<!-- <h4 class="carousel-title white paddless noselect bk-transparent-black">{{current_project.title}}: {{current_project.year}}</h4> -->
							    </div>
							</div>
							<!-- carousel item 4 -->
							<div class="carousel-item spacer">
								<img class="main-slide" 	[@animationState]="state"
															src="{{getSrc(current_project.project_number, 4)}}" 
															srcset="{{getSrcSet(current_project.project_number, 4)}}" 
															sizes="(min-width: 1200px) 1200px, 100vw"
															alt="4 Photo"/>
								<div class="carousel-caption  align-left">
								<!-- <h4 class="carousel-title white paddless noselect bk-transparent-black">{{current_project.title}}: {{current_project.year}}</h4> -->
							    </div>
							</div>
						</div>

					</div>


				</div>
			</div>
		</div>
		<app-return-to-projects></app-return-to-projects>
	</div>
</div>