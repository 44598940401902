<div id="splash_box" ref="scrollDOM" class="container bk-white">
    <div class="jumbotron jobs-bk bk-white row close-gap-bottom close-gap-top under-z no-overflow">
        <div class="row no-pad no-margins ">

            <div class="main-splash-text col-lg-12 col-md-11 col-sm-11 col-xs-11 no-margins">
                <h4 class="h2 black ">We are seeking to hire talented project architects
                    <br> with strong visualisation skills to help us grow!
                </h4>


                <p class="lead ">
                    <a class="action_button bluey btn btn-primary btn-lg " routerLink="/Jobs" role="button">Click here
                        for more </a>
                </p>

            </div>
        </div>
    </div>
</div>