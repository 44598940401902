import { Component, OnInit, OnDestroy } from '@angular/core';
import { ActivatedRoute, Params } from '@angular/router';
import { ProjectDataService } from '../../project-data.service';
import { Project } from '../project.model'; 
import { Subscription } from 'rxjs';
import { trigger, state, transition, style, animate  } from '@angular/animations';

@Component({
  selector: 'app-project',
  templateUrl: './project.component.html',
  styleUrls: ['./project.component.css'],
  animations: [
  	trigger('animationState',[
  			state('start', style({
  				'transform': 'scale(1, 0)'
  			})),
  			state('end', style({
  				'transform':'scale(1, 1)'
  			})),
 			transition('start => end', [
        	animate(200)  			
  		]),

      ])

  ]
})
export class ProjectComponent implements OnInit, OnDestroy {

  current_project: Project;
  current_id;
  project_number;
  state = 'start';
  //observable subscrpition
  paramsSubscription: Subscription;

  constructor(private route:ActivatedRoute, private projectDataService:ProjectDataService) { }

  // onAnimate(){
  // 	this.state == 'start' ? this.state = 'end' : this.state = 'start';
  // }


  ngOnInit(): void {
  	document.body.scrollTop = 0;
  	this.current_id = this.route.snapshot.params['id'];
	// find the project with the id that matches the params passed to in the link
	this.current_project = this.projectDataService.sortedProjects.filter(x => x.id == this.current_id)[0];  	

	console.log("project: " + this.current_project.title);
	//create observable to see if our params change programmatically from this page, ie 'next project button'
	this.paramsSubscription = this.route.params.subscribe(
			(params: Params) => {
				this.current_id = params['id'];
			}
	);

  }

	ngAfterViewInit() {
	  setTimeout( () => {
	    this.state = 'end';
		// console.log("animate!");	 
		document.body.scrollTop = 0;   
	  }, 50);
	}

	getPj(project_number:string){
		return "#" + project_number;
	}

	getSrc(project_number: string, image_number:number){
		// console.log("getsrc");
		return "../assets/projects/" + project_number + "/hero-" + image_number + ".jpg";
	}

	getSrcSet(project_number:string, image_number:number){
		var set = 	"../assets/projects/" + project_number + "/hero-" + image_number + "-sm.jpg 500w" 
					+ ", " + 
					"../assets/projects/" + project_number + "/hero-"+ image_number + "-md.jpg 800w" 
					+ ", " + 
					"../assets/projects/" + project_number + "/hero-"+ image_number + ".jpg 1200w";
		return set;
	}
  ngOnDestroy(){
	//cleanup of observables
	this.paramsSubscription.unsubscribe();
  }

}
