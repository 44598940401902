import { Component, OnInit } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';
import { Location } from '@angular/common';


@Component({
  selector: 'app-services',
  templateUrl: './services.component.html',
  styleUrls: ['./services.component.css']
})
export class ServicesComponent implements OnInit {


  constructor(private router: Router, private location:Location) { 
  	router.events.subscribe(s => {
  		if(s instanceof NavigationEnd){
  			const tree = router.parseUrl(router.url);
  			if(tree.fragment){
  				const element = document.querySelector("#" + tree.fragment);
  				if (element){
  					element.scrollIntoView(true);
  				}
  			}
  		}
  	});
  }

  ngOnInit(): void {}


}
