<div class="jumbotron entice_splash bk-white close-gap-bottom">
	<div class="container">
		<div class="row">
			<div class="col-md-12 section-tagheader ">
				<h2 class="roboto-medium black">The Team<img class="" src="../../assets/tag-line.png" alt="arrow" />
				</h2><br><br>
			</div>
			<div class="col-md-12 black hidden-md hidden-lg spacer">

			</div>

			<div class="row spacer">

				<div class="col-md-2 text-block centered">
					<div class="roboto black ">
						<img class="email" src="../../assets/heads/heads-wojtek.jpg" alt="" /><br>
						<b>Wojtek Rzeszowski &nbsp;</b> <br>MAA, OAA, RAIC
						<br>
						<div><em>Principal</em></div>
						<br><br>
					</div>
				</div>



				<div class="col-md-2 text-block centered">
					<div class="roboto black ">
						<img class="email centre" src="../../assets/heads/heads-revanth.jpg" alt="" /><br>
						<b>Anantha Venkata Revanth &nbsp;</b> <br>

						<div><em>Architectural Intern</em></div>
						<br><br>
					</div>
				</div>

				<div class="col-md-2 text-block centered">
					<div class="roboto black ">
						<img class="email" src="../../assets/heads/heads-alek.jpg" alt="" /><br>
						<b>Alek Rzeszowski &nbsp;</b> <br>

						<div><em>IT, Designer</em></div>
						<br><br>
					</div>
				</div>
				<div class="col-md-2 text-block centered">
					<div class="roboto black ">
						<img class="email" src="../../assets/heads/heads-bozena.jpg" alt="" /><br>
						<b>Bożena Rzeszowska &nbsp;</b> <br>

						<div><em>M.Arch, Partner</em></div>
						<br><br>
					</div>
				</div>
			</div>
		</div>

	</div>
</div>