
		<div  class="jumbotron entice_splash bk-blue close-gap-bottom">
			<div class="container">

				    <div class="col-md-12 text-block ">
				      <h2 class="roboto-medium white ">
							Contact Us <img class="" src="../../assets/tag-line-white.png" alt="arrow" /></h2><br/>

							<form class="col-md-12 text-block" >
				            
				                <div class="row col-md-12 col-sm-12 col-xs-12">
				                    <div class="">

				                        <label class="roboto-medium white" htmlFor="email">Email</label><br>
				                        <input ref="email" id="email" type="email" class="validate col-md-12 col-sm-12 col-xs-12 roboto-medium black" required/><br><br>
				                        
				                    </div>
				                    <div class=" ">

				                        <label class="roboto-medium white" htmlFor="company">Name</label><br>
				                        <input ref="name" id="nameVal" type="text" class="validate col-md-12 col-sm-12 col-xs-12 roboto-medium black"/><br><br>
				                        
				                    </div>
				                </div>
				                <div class="row col-md-12 col-sm-12 col-xs-12">
				                    <div class=" ">
				                    <label class="roboto-medium white" htmlFor="message">How can we help? </label><br>
				                    <textarea rows='5' ref="message" id="messageVal" class="col-md-12 col-sm-12 col-xs-12 roboto-medium black"></textarea><br><br>
				                      
				                    </div>
				                </div>
				             
				             
				                <div class=" row col-md-12 col-sm-12 col-xs-12">
				                    <div class="bk-blue"><br><br>
				                     <p class="align-right"><button class="btn-lg roboto-medium white bk-black " type="submit" name="action">Send Message</button></p>
				                    </div>
				                </div>
                                <div class=" col-md-12 col-sm-12 col-xs-12">
									 <div ref="sentSuccess" class="align-left roboto green "></div>

				                  </div>
				            </form>

				    </div>
				  </div>

		</div>