<div  class="jumbotron return_splash bk-white close-gap-bottom ">
	<div class="container spacer return_up">
		<div class="row ">
			<div class="col-md-12 section-tagheader">

			      	<h2 class="roboto-medium black  text-right">
			      		<!-- <img class="" src="../../assets/arrow_left_up.png" alt="arrow" />  -->
			      		<em class=" noitalics">MENU</em></h2>
			</div>	
		    <div class="col-md-12 white hidden-md hidden-lg spacer"></div>					
		    <div class="col-md-12 white hidden-md hidden-lg spacer"></div>					
		    <div class="col-md-12 white hidden-md hidden-lg spacer"></div>					

		</div>
		<div class="row ">
			<div class="col-md-12 section-tagheader">
			      <a class="pointer" routerLink="/Projects" >
			      	<h2 class="roboto-medium black  text-right">
			      		<!-- <img class="" src="../../assets/arrow_left_up.png" alt="arrow" />  -->
			      		<em class="return-top noitalics">PROJECTS</em></h2></a>
			</div>	
		    <div class="col-md-12 white hidden-md hidden-lg spacer"></div>					
		    <div class="col-md-12 white hidden-md hidden-lg spacer"></div>					
		    <div class="col-md-12 white hidden-md hidden-lg spacer"></div>					

		</div>
		<div class="row ">
			<div class="col-md-12 section-tagheader">
			      <a class="pointer" routerLink="/Services" >
			      	<h2 class="roboto-medium black  text-right">
			      		<!-- <img class="" src="../../assets/arrow_left_up.png" alt="arrow" />  -->
			      		<em class="return-top noitalics">SERVICES</em></h2></a>
			</div>	
		    <div class="col-md-12 white hidden-md hidden-lg spacer"></div>					
		    <div class="col-md-12 white hidden-md hidden-lg spacer"></div>					
		    <div class="col-md-12 white hidden-md hidden-lg spacer"></div>					

		</div>
		<div class="row ">
			<div class="col-md-12 section-tagheader">
			      <a class="pointer" routerLink="/Company" >
			      	<h2 class="roboto-medium black  text-right">
			      		<!-- <img class="" src="../../assets/arrow_left_up.png" alt="arrow" />  -->
			      		<em class="return-top noitalics">COMPANY</em></h2></a>
			</div>	
		    <div class="col-md-12 white hidden-md hidden-lg spacer"></div>					
		    <div class="col-md-12 white hidden-md hidden-lg spacer"></div>					
		    <div class="col-md-12 white hidden-md hidden-lg spacer"></div>					

		</div>
		<div class="row ">
			<div class="col-md-12 section-tagheader">
			      <a class="pointer" routerLink="/Contact" >
			      	<h2 class="roboto-medium black  text-right">
			      		<!-- <img class="" src="../../assets/arrow_left_up.png" alt="arrow" />  -->
			      		<em class="return-top noitalics">CONTACT US</em></h2></a>
			</div>	
		    <div class="col-md-12 white hidden-md hidden-lg spacer"></div>					
		    <div class="col-md-12 white hidden-md hidden-lg spacer"></div>					
		    <div class="col-md-12 white hidden-md hidden-lg spacer"></div>					

		</div>				
	</div>
</div>


