import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-project-featured',
  templateUrl: './project-featured.component.html',
  styleUrls: ['./project-featured.component.css']
})
export class ProjectFeaturedComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
