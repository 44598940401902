import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-trust',
  templateUrl: './trust.component.html',
  styleUrls: ['./trust.component.css']
})
export class TrustComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
